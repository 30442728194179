import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import blogs from '../../api/blogs'
import { useTranslation } from 'react-i18next';

function BlogSidebar({ blLeft, }) {
    const [searchKeyword, setSearchKeyword] = useState('');
    const [tags, setTags] = useState([''])
    const [relatedBlogs, setRelatedBlogs] = useState([''])
    const [randomizedTagKeys, setRandomizedTagKeys] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const allTags = allTagKeys();

    // Get All Tag Key Values
    function allTagKeys() {
        const tagKeys = blogs.map((blog) => {
            return Object.keys(t(`blog.${blog.id}blog.tags`, { returnObjects: true }));
        });

        const tagsKeysFlat = tagKeys.reduce((arr, val) => {
            return arr.concat(val);
        }, []);
        return tagsKeysFlat;
    }

    // Get Translated Tags
    function translateTags(tagKeys) {
        const translatedTags = [];
        tagKeys.forEach(tagKey => {
            blogs.forEach(blog => {
                if (blog.tags && t(`blog.${blog.id}blog.tags.${tagKey}`) !== `blog.${blog.id}blog.tags.${tagKey}`) {
                    translatedTags.push(t(`blog.${blog.id}blog.tags.${tagKey}`));
                }
            });
        });

        return [...new Set(translatedTags)];
    }

    // Random Tags
    function randomArray(tags) {
        let shuffledTags = [...tags];

        for (let i = shuffledTags.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledTags[i], shuffledTags[j]] = [shuffledTags[j], shuffledTags[i]];  // swap elements
        }
        return shuffledTags;
    }

    // Random Blogs
    function shuffleBlogs() {
        let shuffledBlogs = [...blogs];
        for (let i = shuffledBlogs.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledBlogs[i], shuffledBlogs[j]] = [shuffledBlogs[j], shuffledBlogs[i]];  // swap elements
        }
        return shuffledBlogs;
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const tagClickHandler = (e, tag) => {
        if (tag === searchKeyword) {
            e.preventDefault();
        } else {
            setSearchKeyword(tag)
            debouncedSearch(tag)
        }
    }
    //去除多余的空格
    const sanitizeQuery = (query) => {
        return query.trim().replace(/\s+/g, ' ');
    }


    const handleFormSubmit = (e) => {
        e.preventDefault(); // 阻止默认的表单提交

        // if (searchKeyword.length < 1 || searchKeyword >= 100) {
        //     setError(t('lengthError'))
        // } else if (!/^[a-zA-Z0-9 _-|\u4e00-\u9fa5|\u3400-\u4DBF|\uF900-\uFAFF]*$/.test(searchKeyword)) {
        //     // 简繁英,数字、下划线、短划线限定字符
        //     setError(t('typeError'))
        // }


        debouncedSearch(searchKeyword)
    }

    // 防抖函数
    function debounce(fn, delay) {
        let timer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(context, args);
            }, delay);
        };
    }

    // 防抖函数
    const debouncedSearch = debounce((query) => {
        navigate(`/blog?keyword=${sanitizeQuery(query)}&page=${1}`);
    }, 300);


    useEffect(() => {
        if (!randomizedTagKeys.length) {
            setRandomizedTagKeys(randomArray(allTags));
        }
        setRelatedBlogs(shuffleBlogs());
    }, [randomizedTagKeys.length]);

    useEffect(() => {
        setTags(translateTags(randomizedTagKeys));
    }, [randomizedTagKeys, t]);


    return (
        <div className={`col col-lg-4 col-12 ${blLeft}`}>
            <div className="blog-sidebar">
                <div className="widget search-widget">
                    <h3>{t("SearchHere")}</h3>
                    <form onSubmit={handleFormSubmit}>
                        <div>
                            <input type="text"
                                name="searchInput"
                                className="form-control"
                                placeholder={t("SearchHere")}
                                value={searchKeyword}
                                onChange={(e) => {
                                    setSearchKeyword(e.target.value)
                                }}
                            />
                            <button type="submit"><i className="ti-search"></i></button>
                        </div>
                    </form>
                </div>
                <div className="widget tag-widget">
                    <h3>{t("Tags")}</h3>
                    <ul>
                        {tags.slice(0, 5).map((tag, index) => {
                            return <li key={index} ><a className={`${tag === searchKeyword ? 'active-tag' : ''}`} onClick={(e) => tagClickHandler(e, tag)}>{tag}</a></li>
                        })}
                    </ul>
                </div>
                <div className="widget recent-post-widget">
                    <h3>{t("RelatedPosts")}</h3>
                    <div className="posts">
                        {relatedBlogs.slice(0, 3).map((blog, bl) => (
                            <div className="post" key={bl}>
                                <div className="img-holder">
                                    <img src={blog.screens} alt={t(`blog.${blog.id}blog.title`)} />
                                </div>
                                <div className="details">
                                    <h4><Link onClick={ClickHandler} to={`/blog-single/${blog.id}`}>{t(`blog.${blog.id}blog.title`)}</Link></h4>
                                    <span className="date">{t(`blog.${blog.id}blog.create_at`)}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogSidebar;