const cn_translation = {
    Home: "首页",
    AboutUs: "走进西迈",
    Products: "产品中心",
    Blog: "西迈动态",
    Contact: "联系我们",
    ContactUs: "联系我们",
    About: "关于我们",
    SetsOfElectricalEquipments: "成套电气设备（套）",
    Transformers: "变压器（台）",
    PrefabricatedSubstations: "箱变（座）",
    IronAccessories: "铁附件（吨）",
    AnnualCapacity: "年产能",
    heroTitle: "立足中国|服务世界",
    customized: "客制化",
    ourProducts: "产品中心",
    detail: "了解更多",
    QUALITYASSURANCE: "质量保证",
    QualificationCertificates: "荣誉资质",
    BlogPost: "博客",
    LatestNewsFromBlog: "西迈最新动态",
    HONORABLECUSTOMER: "忠实客户",
    Weareworkwithtopbrand: "合作品牌",
    ProductCategory: "产品分类",
    AllProducts: "全部产品",
    OperatingConditions: "操作条件",
    Features: "特点",
    technicalParameters: "技术参数",
    Product: "产品",
    Type: "分类",
    Model: "型号",
    Design: "设计",
    Duration: "制作周期",
    UpdateDate: "更新日期",
    RelatedProducts: "相关产品推荐",
    ProductOverview: "产品总览",
    LatestNews: "最新动态",
    READMORE: "查看详细内容...",
    Back: "返回",
    By: "作者",
    Share: "转发：",
    facebook: "脸书",
    twitter: "推特",
    linkedin: "领英",
    pinterest: "拼趣",
    PreviousPost: "上一则信息",
    Thisisalreadythefirstpost: "这已经是第一则信息了",
    NextPost: "下一则信息",
    Tags: "标签：",
    singleResult: "个结果",
    multipleResults: "个结果",
    Thisisalreadythelastpost: "这已经是最后一则信息了",
    RelatedPosts: "相关信息",
    SearchHere: "搜索",
    SearchPost: "搜索信息",
    lengthError: "搜索关键词空白或者太长",
    typeError: "无效的搜索内容",
    NoResults: "抱歉没有找到与您输入的关键词相关的博客",
    NoResultsHints: "温馨提示：",
    Hint1: "请检查您的输入是否正确",
    Hint2: "尝试点击右边的标签",
    by: "作者： ",
    OopsPageNotFound: "非常抱歉，您要找的页面不见了",
    SorryNotFound: "很抱歉您没有找到想要的页面，可能是您输入的网址有误",
    BacktoHome: "返回主页",
    NotSupportVideo: "您的浏览器不支持视频播放",
    SomethingWentWrong: "出错了",
    ContactAdministratorOrGoBackHome: "请联系管理员或者返回主页",
    contactUspage: {
        AddressTitle: "厂址",
        SpecificAddress: "湖北省鄂州市燕矶镇燕矶金刚石工业园区燕沙路1号",
        ChatwithUs: "在线联系",
        Email: "邮箱：",
        WeChat: "微信",
        CallUs: "联系电话",
        Tel: "座机：",
        Fax: "传真：",
        Phone: "手机：",
        OnlineQuote: "在线表单",
        OnlineQuoteDescription: "为了帮助我们快速为您提供满足您需求的最佳产品解决方案，请提供尽可能多的信息，我们会及时回复您。",
    },
    contactForm: {
        FullName: "姓名",
        YourName: "您的全名",
        Email: "邮箱",
        YourEmail: "您的邮箱",
        Phone: "电话",
        YourPhone: "您的电话",
        Company: "公司 / 机构",
        YourCompany: "您的公司 / 机构",
        HowDidYouFindUs: "您如何了解到我们",
        Choose: "选择",
        ExistingCustomer: "现有客户",
        Google: "谷歌",
        Advertisement: "推广",
        WordofMouth: "口口相传",
        Other: "其他",
        BestTimetoCall: "您方便接听电话的时间段",
        AnyTime: "任何时间",
        Morning: "上午",
        Afternoon: "下午",
        Message: "附加信息",
        MessagePlaceHolder: "如果您有特殊需求，请畅所欲言，我们会尽快联系您",
        SubmitNow: "提交",
        Verify: "验证提交",
        SubmitError: "在提交时出现了一个问题，请联系我们上方的公司电话",
        SubmitSuccess: "你的需求已提交，我们会尽快回复您！",
        VerificationError: "验证码加载错误"
    },
    missionVissionPage: {
        transformersSubstationsTitle: "西迈销往湖北及国际厂商的产品",
        clienteleSatisfaction: "西迈的忠实客户",
        currentCustomersDescription: "我们目前的客户包括中建（中国建筑工程总公司）、一冶（中国第一冶金建设集团）、中铁北京工程集团、国家电网等。他们都对我们的产品和售后服务表示非常满意。",
        operationalLandmarksDescription: "我们的变压器和大型箱变遍布在鄂州的多个地标性建筑，迄今仍在投入使用，例如：",
        customTransformersInfo: "我们还为欧洲、非洲和南亚的客户制造了定制的变压器和配电站，例如1250kVA 33/0.4kV箱式变电站、750kVA 12/0.44kV美式变压器、440V低压开关柜、400kVA 33/0.4kV油浸式变压器、电压变压器以及800kVA 15/0.4kV油浸式变压器。",
        qualityCornerstoneTitle: "以质量创品牌",
        businessTrustSubtitle: "值得您信赖的企业",
        ximaiQualityImportance: "决定企业长足发展的重要因素是质量，质量过硬、用户满意的产品和完善的售后服务，使西迈电气赢得了市场的主动权。公司采用6S管理模式，生产出的产品，来满足广大客户的需要，西迈电气质量管理体系符合ISO9001、环境管理体系符合ISO4001、职业健康管理体系符合OHSAS18001、低压成套设备产品3C认证，其它产品均已通过国家质量监督检验的合格试验，诚信的服务和过硬的质量，使得西迈电气产品的美誉度不断上升。随着公司的主要产品不断通过国家检测机构的合格认证，并通过了国家电网合格供应商资质能力审核。",
        safetyFirstQualityOriented: "2006年至今成功的在国家电网各项目招标中喜获硕果。西迈电气以立足中国、服务世界为导向，在非晶合金变压器的开发、优化结构、节能环保上狠下功夫。为社会提供新型、节能、环保产品是西迈人始终不渝的追求目标。公司以“质量为本，客户至上”为宗旨，连续多年荣获湖北省及鄂州市“守合同重信用”企业的荣誉称号，同时西迈电气LOGO被授予湖北省及鄂州市商标。",
        landmarks: [
            '鄂州中心医院',
            '鄂州花湖机场',
            '鄂州公安局',
            '鄂州疾控中心（中国疾病预防控制中心）',
            "鄂州空临空人民医院",
            '鄂州第三医院',
            '鄂州大学'
        ],
    },
    About4: {
        title: "关于西迈",
        description1: "古城鄂州，交通便利、环境优美。万里长江依市而过。湖北西迈电气设备有限公司（简称“西迈电气”）坐落如此，西迈电气成立于2006年，有着大海一样广阔胸怀、志存高远、立国际前沿的西迈人，以前瞻的眼光开拓海内外市场。 西迈电气占地40000平，新建厂房20000平，具有年产高低压成套设备8000台，变压器6000台，箱式变电站2000台，铁附件10000吨的生产能力 ，智能化节能产品及核心的技术逐年提高。西迈电气在国家电网、发电、交通、智能制造等领域取得了骄人的成绩，其设备产品获得国家有关部门及用户的一致好评。西迈电气拥有外贸权，依托智能制造和品牌优势，产品行销中、亚、非等多个国家和地区。并积极响应国家的战略方针，在电气设备出口的同时，不断加大各国电网建设项目的跟踪，形成以投标为主，配套供货出口为辅的国际业务格局。",
        description2: "我们专注于生产各类不同规格的节能变压器，有干式、油浸式和非晶合金等等。我们的产品线还包括电动汽车充电站、低压开关板如GGD、GCS和GGJ，以及高压柜，高压环网开关，高低压计量柜，以及欧洲或者美式风格的充电站变压器，另外城市建造工程比如公交站台也在我们的经营范围内。长风破浪会有时，直挂云帆济沧海。西迈人将永怀感恩之心，施展抱负，回馈社会，在前进路上不断谱写新的篇章。",
        button: "现在联系"
    },
    testimonial: {
        sectionSubtitle: "客户满意评价",
        sectionTitle: "国际客户评语",
        words: {
            '01': {
                Des: "与西迈电气合作是一次非常特殊的体验。我询问了关于电压变压器的情况，并不仅收到了有竞争力的报价，还收到了详细的蓝图。我们顺利地下了订单，并在2019年9月按计划接收到了高质量的产品。我们强烈推荐！",
                Title: 'Pavel Livandovskyy',
                Sub: "HIGH VOLTAGE UNION-RZVA"
            },
            '02': {
                Des: "我们在线上搜索供应商并参观了多家工厂，但西迈电气真的很出色。他们很有竞争力的报价和强大的生产能力给我们留下了深刻的印象。在确认设计图后，我们下单购买了一个1250kVA 33/0.4kV的箱变。货物在2019年8月按计划到达，我们非常满意！",
                Title: 'Henos Dereb',
                Sub: "Manager"
            },
            '03': {
                Des: "我们在线上找到了西迈电气，他们的专业性立刻吸引了我们的注意。他们不仅提供了有竞争力的价格，而且他们的服务水平也非常出色。虽然我们有多个选择，但他们的声誉决定了我们的决策。收到货物，所有流程都很顺利。",
                Title: 'Jesse Mouttet',
                Sub: "Marketer"
            }

        }
    },
    footer: {
        address: "湖北省鄂州市燕矶镇燕矶金刚石工业园区燕沙路1号",
        tel: "电话: (0086) 0711-5921007",
        fax: "传真: (0086) 0711-5921066",
        email: "邮箱: hbxmdq@163.com",
        company: "西迈电气",
        aboutCompany: "关于我们",
        products: "产品中心",
        contactUs: "联系我们",
        visionValues: "西迈企训",
        recentPost: "最近博客",
        copyright: "Copyright &copy; 2022 //  All Right Reserved by",
        history: "History",
        awards: "Awards",
        leadership: "Leadership",
        ContactUsNow: "现在联系我们！"
    },
    HeaderTopbar3: {
        address: "湖北省鄂州市燕沙路1号"
    },
    AboutHome: {
        heroTitle1: "西迈电气",
        heroTitleHighlight1: "设计",
        heroTitle2: "和",
        heroTitleHighlight2: "生产",
        heroTitle3: "变压器",
        heroTitle4: "和",
        heroTitleHighlight3: "箱变以及各种电气设备",
        description: "西迈电气占地40000平，新建厂房20000平，具有年产高低压成套设备8000台，变压器6000台，箱式变电站2000台，铁附件10000吨的生产能力 ，智能化节能产品及核心的技术逐年提高。西迈电气在国家电网、发电、交通、智能制造等领域取得了骄人的成绩，其设备产品获得国家有关部门及用户的一致好评。| 我们专注于生产各类不同规格的节能变压器，有干式、油浸式和非晶合金等等。我们的产品线还包括电动汽车充电站、低压开关板如GGD、GCS和GGJ，以及高压柜，高压环网开关，高低压计量柜，以及欧洲或者美食风格的充电站变压器，另外城市建造工程比如公交站台也在我们的经营范围内。长风破浪会有时，直挂云帆济沧海。西迈人将永怀感恩之心，施展抱负，回馈社会，在前进路上不断谱写新的篇章。",
        exploreBtn: "发现更多"
    },
    service: {
        "transformerTitle": "变压器",
        "transformerDescription": "干式、油浸式和非晶合金变压器",
        "switchgearTitle": "开关柜",
        "switchgearDescription": "KYN28、GCS、KYN28A-12和GGD型号。",
        "substationTitle": "箱变",
        "substationDescription": "箱式变电站（适用于欧式和美式）。",
        "accessoriesTitle": "配件",
        "accessoriesDescription": "空中绝缘、变压器和线路配件等。"
    },
    Qualification: {
        "1": {
            "title": "世标认证",
            "subTitle": "变压器、箱变、变电柜、铁附件生产许可"
        },
        "2": {
            "title": "世标认证",
            "subTitle": "变压器、箱变、变电柜、铁附件生产许可"
        },
        "3": {
            "title": "世标认证",
            "subTitle": "变压器、箱变、变电柜、铁附件生产许可"
        },
        "4": {
            "title": "中国强制性产品认证",
            "subTitle": "配电安装质量认证"
        },
        "5": {
            "title": "中国强制性产品认证",
            "subTitle": "单向、三相结合补偿，电容器，复合开关质量认证"
        },
        "6": {
            "title": "中国强制性产品认证",
            "subTitle": "低压开关柜质量认证"
        },
        "7": {
            "title": "中国强制性产品认证",
            "subTitle": "配电板质量认证"
        },
        "8": {
            "title": "中国强制性产品认证",
            "subTitle": "低压开关柜质量认证"
        },
        "9": {
            "title": "中国强制性产品认证",
            "subTitle": "低压开关柜质量认证"
        }
    },
    Patents: {
        '1': {
            "title": "外观专利",
            "subTitle": "配电变压器"
        },
        '2': {
            "title": "实用新型专利",
            "subTitle": "新型安装配电箱 1/2"
        },
        '3': {
            "title": "实用新型专利",
            "subTitle": "新型安装配电箱 2/2"
        },
        '4': {
            "title": "实用新型专利",
            "subTitle": "新型便检修与维护的低压开关柜 1/2"
        },
        '5': {
            "title": "实用新型专利",
            "subTitle": "新型便检修与维护的低压开关柜 2/2"
        },
        '6': {
            "title": "实用新型专利",
            "subTitle": "新型阻隔变电柜 1/2"
        },
        '7': {
            "title": "实用新型专利",
            "subTitle": "新型阻隔变电柜 2/2"
        },
        '8': {
            "title": "实用新型专利",
            "subTitle": "新型避雷变电柜 1/2"
        },
        '9': {
            "title": "实用新型专利",
            "subTitle": "新型避雷变电柜 2/2"
        },
        '10': {
            "title": "实用新型专利",
            "subTitle": "新型防水箱变 1/2"
        },
        '11': {
            "title": "实用新型专利",
            "subTitle": "新型防水箱变 2/2"
        },
        '12': {
            "title": "实用新型专利",
            "subTitle": "新型减震变压器 1/2"
        },
        '13': {
            "title": "实用新型专利",
            "subTitle": "新型减震变压器 2/2"
        },
        '14': {
            "title": "实用新型专利",
            "subTitle": "新型可拆卸变压器底框 1/2"
        },
        '15': {
            "title": "实用新型专利",
            "subTitle": "新型可拆卸变压器底框 2/2"
        },
        '16': {
            "title": "实用新型专利",
            "subTitle": "新型干式变压器的冷却降温结构 1/2"
        },
        '17': {
            "title": "实用新型专利",
            "subTitle": "新型干式变压器的冷却降温结构 2/2"
        },
        '18': {
            "title": "实用新型专利",
            "subTitle": "组装式多功能低压配电柜 1/2"
        },
        '19': {
            "title": "实用新型专利",
            "subTitle": "组装式多功能低压配电柜 2/2"
        }
    },
    ProductCategories: {
        "PrefabricatedSubstation": "箱式变电站",
        "Switchgear": "高低压开关柜",
        "Transformer": "变压器",
        "showcategories": "展示分类菜单",
        "CT&PT": "互感器",
    },
    products: {
        0: {
            "name": "欧式预装式箱式变电站",
            "model": "YB£-12/0.4-£",
            "category": "箱式变电站",
            "shortDescription": "广泛用于城市电网改造、住宅小区、高层建筑、工矿、宾馆、商场、机场、铁路、油田、码头、高速公路以时及临性用电设施等户内外场所。",
            intro: "广泛用于城市电网改造、住宅小区、高层建筑、工矿、宾馆、商场、机场、铁路、油田、码头、高速公路以时及临性用电设施等户内外场所。",
            duration: "7天",
            updateDate: "2023年9月27日",
            "features": {
                "0": "高压开关设备、变压器、低压开关设备三位一体，成套性强",
                "1": "高、低压保护完善，运行安全可靠，维护简单；",
                "2": "占地少,投资省、生产周期短、移动方便；",
                "3": "接线方案灵活多样；",
                "4": "结构独特:独特蜂窝式结构双层(复合板)外壳牢固，隔热又散热通风、美观、防护等级高，外壳材料有不锈钢钦合金、铝合金、冷轧板、彩钢板可选；",
                "5": "型式多样:通用型、别墅型、紧凑型等多种样式；",
                "6": "高压环网柜内可装配网自动化终端(FTU)实现短路及单相接地故障的可靠检测，具备“四遥”功能，便于配网自动化升级。"
            },
            "condition": {
                "0": "海拔高度不超过1000m；",
                "1": "环境温度: -25℃~+40℃；",
                "2": "相对湿度:日平均值不大于95%,月平均值不大于90%；",
                "3": "安装场所:无火灾、爆炸危险、导电尘埃、化学腐蚀性气体及剧烈震动的场所，若超出以上条件时，用户可与我公司协商。"
            }
        },
        "1": {
            "name": "美式预装式箱式变电站",
            "model": "YB27-12",
            "category": "Prefabricated Substation",
            "shortDescription": "YB27-12系列美式预装式箱式变电站，适用于10KV环网供电，双电源供电或终端供电系统中，作为变电、计量、补偿控制和保护装置。",
            "intro": "本产品是吸收国外最新先进技术，结合国内实际情况研制开发的，整台产品具有体积小、安装维护简便、低噪音、低损耗、防盗、过负荷能力强、全保护等特点。适用于新建小区、绿化带、公园、车站宾馆、工地、机场等场所。YB27- 12系列美式预装式箱式变电站，适用于10KV环网供电，双电源供电或终端供电系统中，作为变电、计量、补偿控制和保护装置。",
            duration: "10天",
            updateDate: "2023年9月27日",
            "features": {
                "0": "全绝缘、全密封、免少维护、可靠保证人身安全；",
                "1": "可采用分箱式结构，避免变压器油箱内油的污染；",
                "2": "高压侧采用双熔丝全范围保护，大大降低成本；",
                "3": "既可用环网，也可用于终端，电缆头可在200A负荷电流时紧急插拔；",
                "4": "箱体采用蜂窝式双夹层复合板，隔温又散热的功能；",
                "5": "箱体采用蜂窝式双夹层复合板，隔温又散热的功能；",
                "6": "低压侧加装电子缺相保护器，当系统内出现不正常电压时，可快速分断主进开关；",
                "7": "高压侧油浸式负荷开关或SF6负荷开关，可电动升级， 为实现配网自动化打下基础；",
                "8": "采用油浸式S9或性能更优的S11系列变压器。"
            },
            "condition": {
                "0": "海拔高度不超过1000m； ",
                "1": "环境温度: -35℃~+40℃；",
                "2": "相对湿度:日平均值不大于95%，月平均值不大于90%；",
                "3": "安装场所:无火灾、爆炸危险、化学腐蚀性气体及通风良好的场所，地面倾角不大于3°"
            }
        },
        "2": {
            "name": "铠装移开式封闭开关设备",
            "model": "KYN28A-12",
            "category": "开关柜",
            "duration": "3天",
            "updateDate": "2023年9月27日",
            "type": "Indoor AC Metal-Clad Mid-mounted Switchgear",
            "shortDescription": "KYN28A-12型户内交流金属铠装中置式开关设备。它适用于三相交流额定电压12kV,额定频率50Hz电力系统，用于接受和分配电能并对电路实行控制、保护及监测。",
            "intro": "开关设备按GB3906-91中的铠装金属封闭开关设备而设计。整体是由柜体和中置式可抽出部件(即手车)两大部分组成。柜体分四个单独的隔室，外壳防护等级为IP4X，各小室间和断路器室门打开时等级为IP2X。具有架空进出线、电缆进出线及其它功能方案，经排列、组合后形成一套完整的配电系统装置。本开关设备可以从正面进行安装、调试和维护，因此它可以背靠背、组成双重排列和靠墙安装，提高了开关设备的安全性、灵活性、减少了占地面积。",
            "features": {
                "0": "开关设备按GB3906-91中的铠装金属封闭开关设备而设计。整体是由柜体和中置式可抽出部件(即手车)两大部分组成。柜体分四个单独的隔室，外壳防护等级为IP4X，各小室间和断路器室门打开时等级为IP2X；",
                "1": "具有架空进出线、电缆进出线及其它功能方案，经排列、组合后形成一套完整的配电系统装置；",
                "2": "本开关设备可以从正面进行安装、调试和维护，因此它可以背靠背、组成双重排列和靠墙安装，提高了开关设备的安全性、灵活性、减少了占地面积；"
            },
            "condition": {
                "0": "周围空气温度:最高温度+40℃。最低温度-15℃；",
                "1": "相对湿度:日平均相对湿度:≤95%；日平均水蒸气压力不超过2.2KPa；月平均相对湿度: < 90%；月平均水蒸气力不超过1.8KPa；",
                "2": "海拔高度: 1000m 以下；",
                "3": "地震烈度:不超过8度；",
                "4": "周围空气应不受腐蚀性或可燃气体、水蒸气等明显污染；",
                "5": "无剧烈振动场所；",
                "6": "超出GB3906规定的正常条件下使用时，由用户和本公司协商定制。"
            }
        },
        "3": {
            "name": "低压成套开关柜",
            "model": "GGD",
            "category": "开关柜",
            "duration": "3天",
            "updateDate": "2023年9月27日",
            "shortDescription": "GGD型交流低压配电柜适用于发电厂、变电站、工矿企业等电力用户的交流50Hz,额定工作电压380V,额定工作电流5000A的配电系统，做为动力、照明及配电设备的电能转换、分配与控制之用。",
            "type": "AC Low Voltage Power Distribution Cabinet",
            "intro": "GGD型交流低压配电柜适用于发电厂、变电站、工矿企业等电力用户的交流50Hz,额定工作电压380V,额定工作电流5000A的配电系统，做为动力、照明及配电设备的电能转换、分配与控制之用。产品具有分断能力高、动热稳定性好、电气方案灵活、组合方便、实用性强，结构新颖、防护等级高等特点。可作为低压成套开关设备的更新换代产品使用。",
            "features": {
                "0": "分断能力高、动热稳定性好",
                "1": "电气方案灵活、组合方便、实用性强，结构新颖、防护等级高；",
                "2": "可作为低压成套开关设备的更新换代产品使用。"
            },
            "condition": {
                "0": "周围空气温度不高于+40℃,不低于-5℃。24h以内的平均温度不得高于+35℃；",
                "1": "户内安装使用，使用地点的海拔高度不得超过2000m；",
                "2": "周围空气相对湿度在最高温度为+40℃时不超过50%，在较低温度时允许有较大的相对湿度。( 例如+20℃时为90% )应考虑到由于温度变化可能会偶然产生凝露的影响；",
                "3": "设备安装时与垂直面的倾斜度不超过5%；",
                "4": "设备应安装在无剧烈震动和冲击的地方，以及不足以使电器元件受到腐蚀的场所；",
                "5": "用户有特殊要求时与本公司协商定制。"
            },
            table: {
                headers: [
                    "型号",
                    "额定电压（V）",
                    "额定电流（A）",
                    "额定短路开断电流（KA）",
                    "额定短时耐受电流（1S）（KA）",
                    "额定峰值耐受电流（KA）"
                ],
                rows: [
                    ["CCD1", "380", "A1000", "15", "15", "30"],
                    ["", "", "B600(630)", "", "", ""],
                    ["", "", "C400", "", "", ""],
                    ["GGD2", "380", "A1500(1600)", "30", "30", "63"],
                    ["", "", "B1000", "", "", ""],
                    ["", "", "C600", "", "", ""],
                    ["GGD3", "380", "A3200", "50", "50", "105"],
                ],
            },
        },
        "4": {
            "name": "低压无功智能补偿装置",
            "model": "GGJ",
            "category": "开关柜",
            "duration": "3天",
            "updateDate": "2023年9月27日",
            "shortDescription": "GGJ低压无功智能补偿装置对无功量实行智能化跟踪补偿，广泛应用于低压电网，提高功率因数，减少无功损耗，改善供电质量",
            "intro": "GGJ低压无功智能补偿装置采用计算机辅助设计(CAD)，引入微电脑控制，对无功量实行智能化跟踪补偿，其结构合理，技术先进，广泛应用于低压电网，提高功率因数，减少无功损耗，改善供电质量，是新一代的节电产品。专用于130-600KVA三相变压器的无功功率补偿。",
            "features": {
                "0": "智能控制器控制；",
                "1": "功能齐全，性能可靠,补偿方式自动；",
                "4": "可将功率因数提高至0.9以上；",
                "5": "实时显示电网功率因数，显示范围:滞后(0.00-0.99)，超前(0.00-0.99)；",
                "6": "具有过电压、过谐波、过补偿、系统故障、缺相、过载等多种综合保护功能；",
                "7": "记忆已设定的参数，系统停电后不会丢失参数，电网恢复正常后，自动进入运行状态，无须人员值守；",
                "8": "可根据电网负荷平衡状况，采取分相补偿或混合补偿；",
                "9": "抗干扰能力强，能抵御从电网直接输入的幅值2000V干扰脉冲，高于国家专业标准;",
                "10": "实时测量和整点纪录变压器低压侧的三相电压、电流、频率、有功功率、无功功率、功率因数、有功电度、无功电度:电压电流总畸变率和2-25次谐波含量；",
                "11": "具有RS-232和RS-485接口，可进行掌上电脑数据抄录，也可通过远程通讯功能实现无线抄表,装置测试，参数设置和时实测量数据及纪录数据的读取；",
                "12": "数据分析功能:可对运行负荷数据进行分析处理、统计查询；综合分析供电质量，计算电压合格率，供电负荷率,可靠率及最大负荷率;分时段查询功率因数、有功功率和无功功率；绘制各相电压、电流、功率因数等曲线图:打印综合分析及统计报表。"
            },
            "technicalParameters": {
                "0": "额定电压:0.38-0.66kV；",
                "1": "额定频率:50Hz；",
                "2": "额定容量:1-600kvar；",
                "3": "适用电压范围:(0.85-1.1) 倍额定电压；",
                "4": "最大允许电流:1.3倍额定电流；",
                "5": "控制回路:1-16回路；",
                "6": "投切时间:1-150S/次，可调；",
                "7": "工作方式:自动，连续运行。"
            }
        },
        "5": {
            "name": "低压抽出式开关柜",
            "model": "GCS",
            "category": "开关柜",
            "duration": "3天",
            "updateDate": "2023年9月27日",
            "shortDescription": "GCS型低压抽出式开关柜，适用于发电厂、石油、化工、冶金、纺织、高层建筑等行业的配电系统。",
            "intro": "GCS型低压抽出式开关柜，适用于发电厂、石油、化工、冶金、纺织、高层建筑等行业的配电系统。在大型发电厂、石化系统等自动化程度高，要求与计算机接口的场所，作为三相交流频率为50(60)Hz、额定工作电压为400V、660V,额定电流为5000A及以下的发、供电系统中的配电、电动机集中控制、无功功率补偿使用的低压成套配电装置。",
            "condition": {
                "0": "周围空气温度不高于+40℃，不低于-5℃ , 24小时内平均温度不得高于+35℃。超过时，需根据实际情况降容运行；",
                "1": "户内使用；",
                "2": "使用地点的海拔高度不得超过2000m；",
                "3": "周围空气相对湿度在最高温度为+40℃时不超过50%，在较低温度时允许有较大的相对湿度，如+20℃时为90%，应考虑到由于温度的变化可能会偶然产生凝露的影响；",
                "4": "装置安装时与垂直面的倾斜度不超过5°，且整组柜列相对平整(符GBJ232-82标准)；",
                "5": "装置应安装在无剧烈震动和冲击以及不足以使电器元件受到不应有腐蚀的场所；",
                "6": "用户有特殊要求时，可以与我司协商定制解决。"
            },
            table: {
                headers: ["序号", "名称", "参数"],
                rows: [
                    ["1", "主电路额定电压(V)", "交流400/660"],
                    ["2", "辅助电路额定电压", "交流220、380(400)、直流110、220"],
                    ["3", "额定频率(Hz)", "50(60)"],
                    ["4", "额定绝缘电压(V)", "660"],
                    ["5", "额定电流(A) - 水平母线", "≤5000"],
                    ["", "额定电流(A) - 垂直母线(MCC)", "1000"],
                    ["6", "母线额定短时耐受电流(KA/1s)", "50，180"],
                    ["7", "母线额定峰值耐受电流(KA/0.1s)", "105，176"],
                    ["8", "工频试验电压(V/1min) - 主电路", "2500"],
                    ["", "工频试验电压(V/1min) - 辅助电路", "2000"],
                    ["9", "母线 - 三相四线制", "A.B.C.PEN"],
                    ["", "母线 - 三相五线制", "A.B.C.PE.N"],
                    ["10", "防护等级", "IP30.IP40"]
                ],
            }
        },
        "6": {
            "name": "干式变压器",
            "model": "SCB11",
            "category": "变压器",
            "duration": "7天",
            "updateDate": "2023年9月27日",
            "shortDescription": "干式变压器广泛用于局部照明、高层建筑 、机场，码头CNC机械设备等场所，简单的说干式变压器就是指铁芯和绕组不浸渍在绝缘油中的变压器。",
            "intro": "干式变压器广泛用于局部照明、高层建筑 、机场，码头CNC机械设备等场所，简单的说干式变压器就是指铁芯和绕组不浸渍在绝缘油中的变压器。",
            "features": {
                "0": "安全，防火，无污染，可直接运行于负荷中心；",
                "1": "采用国内先进技术，机械强度高，抗短路能力强，局部放电小，热稳定性好，可靠性高，使用寿命长；",
                "2": "低损耗，低噪音，节能效果明显，免维护；",
                "3": "散热性能好，过负载能力强，强迫风冷时可提高容量运行；",
                "4": "防潮性能好，适应高湿度和其他恶劣环境中运行；",
                "5": "干式变压器可配备完善的温度检测和保护系统。采用智能信号温控系统，可自动检测和巡回显示三相绕组各自的工作温度，可自动启动、停止风机，并有报警、跳闸等功能设置；",
                "6": "体积小，重量轻，占地空间少，安装费用低。"
            },
            "technicalParameters": {
                "0": "使用频率:50 / 60HZ ；",
                "1": "空载电流 < 4 % ；",
                "2": "耐压强度:20000V / min无击穿;测试仪器:YZ1802 耐压试 验仪(20mA)；",
                "3": "绝缘等级:F级(特殊等级可定制)；",
                "4": "绝缘电阻:≥2M欧姆测试仪器:ZC25B 一4 型兆欧表<1000 V)；",
                "5": "连接方式:Y/Y 、△/Y0 、Yo/△，自耦式(可选)；",
                "6": "线圈允许温升:100K；",
                "7": "散热方式:自然风冷或温控自动散热；",
                "8": "噪音系数:≤30dB。"
            },
            "condition": {
                "0": "操作温度：0-40(℃)；",
                "1": "相对湿度<70%；",
                "2": "海拔高度:不超过2500米；",
                "3": "防止工作在腐蚀性液体、或气体、尘埃、导电纤维或金属细屑较多的场所；",
                "4": "防止工作在振动或电磁干扰场所；",
                "5": "避免长期倒置存放和运输，不能受强烈的撞击。"
            }
        },
        "7": {
            "name": "油浸式变压器",
            "model": "S11",
            "category": "变压器",
            "duration": "7天",
            "updateDate": "2023年9月27日",
            "shortDescription": "油浸式变压器主要作为改变电压之用，通常是将输电网接入的10kv或35kv电能降压为380V或220V，方便用户使用。",
            "intro": "油浸式变压器主要作为改变电压之用，通常是将输电网接入的10kv或35kv电能降压为380V或220V，方便用户使用。油变采用全密封装置，可以用于室内或室外，在恶劣环境下也可以使用，环境适应性强。",
            "features": {
                "0": "油浸式电力变压器的低压绕组一般采用铜箔绕组的圆柱形结构，但容量较小的除外使用铜线。高压绕组采用多层圆柱结构，使绕组安匝分布均匀，漏磁小，机械强度高，抗短路能力强。",
                "1": "铁芯和绕组分别紧固。装置的高压部分、低压引线等紧固部位装有自锁防脱螺母。采用不挂心结构，可承受运输过程中的颠簸。",
                "2": "线圈和铁芯采用真空干燥处理，变压器油采用真空滤油注油技术，最大限度地减少油浸式电力变压器内部的水分。",
                "3": "油箱采用波纹板，具有呼吸功能，可补偿因温度变化引起的油量变化。因此，该产品没有储油箱，明显降低了变压器的高度。",
                "4": "由于波纹板取代了储油罐，变压器油与外界隔绝，有效防止氧气和水的进入，导致绝缘性能下降。",
                "5": "根据以上四点，油浸式电力变压器在正常运行时无需换油，大大降低了维护成本，延长了变压器的使用寿命。"
            },
            "technicalParameters": {
                "0": "额定容量: 50-2000kVA",
                "1": "额定电压: 10KV 或 35KV",
                "2": "电压调整: 断路或半自动",
                "3": "绝缘等级: F级"
            },
            "condition": {
                "0": "海拔高度：≤1500米；",
                "1": "较大风速：35M/S；",
                "2": "海拔高度:不超过2500米；",
                "3": "较大相对湿度：≤90%（25℃时）；",
                "4": "较高环境温度：+45℃；",
                "5": "较热月平均温度：+ 30℃",
                "6": "较低环境温度：- 40℃",
                "7": "较大日温差：25℃",
                "8": "覆冰：10MM",
                "9": "地震地面水平加速度：0.3g",
                "10": "污秽等级IV级"
            }
        },
        "8": {
            "name": "室内电压互感器",
            "model": "JDZX9-10",
            "category": "互感器",
            "duration": "7-15天",
            "updateDate": "2024年1月17日",
            "shortDescription": "室内型环氧树脂电压互感器，轻便高效。",
            "intro": "本型电压互感器为环氧树脂浇注全绝缘全封闭结构，适用于户内。在50Hz-60Hz，额定电压10kv及以下的中性点非有效接地系统中做电压测量，电能计量和继电保护用。尺寸小、重量轻，二次出线端有接线端子盒，接线非常方便。",
            "features": {
                "0": "采用先进的环氧树脂材料，确保产品的全封闭和全绝缘性能，提高安全性能。",
                "1": "设计紧凑，体积小巧，便于安装和节省空间。",
                "2": "轻量化设计，便于搬运和安装，减少安装成本。",
                "3": "配备二次出线端子盒，简化接线过程，提高了安装效率。",
                "4": "为电压测量、电能计量和继电保护提供精确可靠的数据支持。",
                "5": "经久耐用，长期稳定运行，减少维护成本。"
            },
            "technicalParameters": {
                "0": "额定绝缘水平：12/42/75kv",
                "1": "额定频率：50/60Hz",
                "2": "负载的功率因数：COSφ=0.8（滞后）；",
                "3": "产品标准：GB20840.1（2，3，4）"
            },
            "condition": {
                "0": "海拔高度：≤1500米；",
                "1": "较大风速：35M/S；",
                "2": "海拔高度:不超过2500米；",
                "3": "较大相对湿度：≤90%（25℃时）；",
                "4": "较高环境温度：+45℃；",
                "5": "较热月平均温度：+ 30℃",
                "6": "较低环境温度：- 40℃",
                "7": "较大日温差：25℃",
                "8": "覆冰：10MM",
                "9": "地震地面水平加速度：0.3g",
                "10": "污秽等级IV级"
            }
        },
        "9": {
            "name": "LZZW-10型电流互感器",
            "model": "LZZW-10",
            "category": "互感器",
            "duration": "7-15天",
            "updateDate": "2024年1月17日",
            "shortDescription": "户外电流互感器，高耐久性，适用多环境条件。",
            "intro": "本型户外电流互感器为环氧树脂全封闭支柱产品， 适用于额定频率50Hz或60Hz，额定电压10kV及以下电力系统中，作电能计量、电流测量和继电保护用。 本产品符合IEC185及GB1208-2006《电流互感器》标准。具有耐电弧、耐紫外线、耐老化、寿命长等特点。",
            "features": {
                "0": "使用环氧树脂材料全封闭支柱结构，增强其耐用性和安全性。",
                "1": "具备优秀的耐电弧、耐紫外线和耐老化特性，适用于户外环境。",
                "2": "提供多种准确级别（0.5/0.5S/0.2/0.2S/5P10/10P10），满足不同精度需求。",
                "3": "提供红色和灰色两种颜色选择，适应不同的视觉和环境需求。",
                "4": "适用于电能计量、电流测量和继电保护，多功能应用。",
                "5": "污染等级为Ⅳ级，增强在重污染环境下的使用可靠性。"
            },
            "technicalParameters": {
                "0": "额定电压：10kV",
                "1": "额定电压：10kV",
                "2": "额定一次电流：5~1000A",
                "3": "额定二次电流：5A 或 1A",
                "4": "额定绝缘水平：12/42/75kV",
                "5": "准确级：0.5/0.5S/0.2/0.2S/5P10/10P10",
                "6": "负荷：5VA/10VA/客户定制",
            },
            "condition": {
                "0": "海拔高度：≤1500米；",
                "1": "较大风速：35M/S；",
                "2": "海拔高度:不超过2500米；",
                "3": "较大相对湿度：≤90%（25℃时）；",
                "4": "较高环境温度：+45℃；",
                "5": "较热月平均温度：+ 30℃",
                "6": "较低环境温度：- 40℃",
                "7": "较大日温差：25℃",
                "8": "覆冰：10MM",
                "9": "地震地面水平加速度：0.3g",
                "10": "污秽等级IV级"
            }
        }
    },
    productsText: {
        discuss: "有想要的产品？随时联系我们！",
        touch: "让我们谈谈您的需求，让我们来帮助您和您的项目"
    },
    blog: {
        "1blog": {
            "title": "西迈电气简介",
            "tags": {
                "Introduction": "简介"
            },
            "description": [
                "湖北西迈电气设备有限公司（简称'西迈电气'）成立于2006年，注册资金2000万人民币。公司员工超过100人，其中超过90%的员工从事技术生产和研发。",
                "作为一家知识密集型、技术力量雄厚的创新公司，依托人才和技术优势，西迈电气主要生产SH15、S13、S11和SCB11型变压器，箱式变电站，KYN28高压开关柜，GCS、GGD、GGJ、电源柜、配电箱及铁制附件设备，同时也承接电气工程项目的总承包。产品质量管理体系符合ISO9001标准，环境管理体系符合ISO14001标准，职业健康安全管理体系符合OHSAS18001标准，CCC认证，并已通过国家质量监督检验中心的合格测试，并拥有多项专利。",
                "我们的产品已达到国家电网湖北电力公司的标准，证明了我们公司成为其合格供应商的能力。我们曾获得2011年鄂州市'守合同重信用企业'、2010-2011年湖北省'守合同重信用企业'、2011-2012年'消费者满意商品'、2012-2013年鄂州市'守合同重信用企业'、2014年湖北省'守合同重信用企业'的荣誉称号。与此同时，我们的品牌还被授予2014-2018年鄂州市'知名商标'和2014-2019年湖北省'著名商标'。",
                "通过引进先进的理念和使用尖端的技术，我们在国家电网招标项目中取得了成功的成果，例如：2015年国家电网湖北电力公司的首家集中采购（铁制配件），国家电网湖北电力公司的配电网设备协议库存（新批次），国家电网恩施电力公司的二期（25-36 F#）电源工程（材料采购和监管），2012年湖北电力公司的农村网络夏季高峰应急修复工程，湖北电力公司的乡村电力项目，2011年11月至2012年11月湖北电力公司的新住宅建设支持项目，2011年湖北电力公司随州市农村电力升级的配电网项目，2010年12月至2011年12月湖北电力公司的新住宅建设支持项目和城市低压配电网项目，2010年湖北电力公司的第五批农村电力升级项目（网络配电）用于广水、随州市。设备的运行状况获得了用户的普遍好评。",
                "本着'立足中国，服务世界'的原则，并结合市场需求，西迈电气专注于新产品的开发、产品结构的优化、节能和环保。提供新颖、节能、环保的产品是我们坚定的追求。以'安全第一、质量为先、客户至上'为目标，我们努力树立良好的声誉并提供诚信的服务。",
                "欢迎您的到来和指导，所有西迈电气的员工都在等待您的莅临！"
            ],
            "author": "喻迁亦",
            "authorTitle": "喻迁亦",
            "quote": "通过我们的介绍视频了解西迈，了解我们的项目和质量保障。",
            "blockquote": "",
            "create_at": "2020年4月15日",
            "blClass": "format-video"
        },
        "2blog": {
            "title": "为临空区人民医院提供专业的电力供应",
            "tags": {
                "Medical_Infrastructure": "医疗基础设施",
                "Electrical_Engineering": "电气工程",
                "Urban_Development": "城市发展"
            },
            "description": [
                "我们非常荣幸地宣布，我们近期与临空区人民医院合作，承担了该医院的专业电力供应项目。确保医疗机构得到持续且高效的电力供应至关重要。在现代医疗器械和关键的患者护理系统都严重依赖电力的背景下，强大而可靠的电力基础设施成为现代医疗的基石。",
                "我们经验丰富的团队精心设计并实施了一个与医院独特需求相一致的电源系统。从急诊室到手术室，我们确保每个部门都获得不间断的电力供应，降低风险并提高患者护理标准。",
                "这次合作体现了我们对加强公共卫生基础设施的深厚承诺。我们了解城市医疗设施的挑战，并致力于提供符合现代医疗机构高标准期望的解决方案。",
                "在期待未来更多的合作时，我们与临空区人民医院的合作证明了我们的专业能力和对社区福祉的承诺。"
            ],
            "author": "喻迁亦",
            "authorTitle": "喻迁亦",
            "quote": "用专业的电气解决方案赋能医疗。医疗卓越的核心是坚实的基础设施。",
            "blockquote": "",
            "create_at": "2021年8月11日",
            "blClass": "format-standard-image"
        },
        "3blog": {
            "title": "鄂州市第一医院电力配电改造",
            "tags": {
                "Infrastructure_Upgrade": "基础设施升级",
                "Medical_Infrastructure": "医疗基础设施"
            },
            "description": [
                "我们非常荣幸地宣布，我们最近参与了鄂州第一医院的电力配电改造项目。我们专业且技术娴熟的团队凭借明确的愿景开展了此项目，目的是提高医院电力系统的效率和可靠性。通过精心的规划、前沿的解决方案和对品质的坚定承诺，我们成功地将医院的电力分配提升到了一个新的水平。",
                "这样的项目突显了健壮且高效的电力系统的重要性，特别是在不间断电源至关重要的医疗环境中。凭借过去成功完成的项目经验，我们的团队非常适合承担这一责任。我们与医院管理层密切合作，确保对他们的运营干扰最小化。升级后的电气系统不仅承诺提供高效的电力分配，而且还提供了更好的安全性、耐用性和未来准备性。",
                "我们认识到电力分配在这种关键机构的顺利运行中所起的关键作用，并继续致力于提供无与伦比的服务。一如既往，我们的努力受到对客户、社区以及希望产生有意义影响的愿望的驱动。",
                "这一成就证明了我们不懈的奉献精神和技术实力。我们深感感谢鄂州第一医院的管理层对我们的信任，并期待在未来有更多的合作机会。"
            ],
            "author": "喻迁亦",
            "authorTitle": "喻迁亦",
            "quote": "为鄂州第一医院等关键机构提供无与伦比的专业技术。我们对卓越的承诺确保了用电更安全、更可靠的医院。",
            "blockquote": "",
            "create_at": "2023年4月15日",
            "blClass": "format-standard-image"
        },
        "4blog": {
            "title": "在新冠疫情期间为鄂州大学隔离区扩大电力容量",
            "tags": {
                "COVID-19": "新冠疫情",
                "University_Infrastructure": "大学基础设施",
                "Electrical_Capacity_Upgrade": "电容升级",
                "Social_Responsibility": "社会责任"
            },
            "description": [
                "我们很自豪地宣布，在疫情期间，湖北西迈电气设备有限公司为鄂州大学的隔离区扩大了电力容量。在危机时刻，我们为服务社区的决心得到了真正的体现。",
                "随着疫情的爆发，鄂州大学隔离区的电力需求激增。我们的专业团队，凭借在电力容量扩展方面的专业知识，努力并迅速地工作，以满足这些增加的需求。确保机构能够依赖稳定而强大的电源，以确保隔离区的顺利运行是至关重要的。",
                "我们了解在疫情期间，这样的努力所承担的巨大责任。通过精心的规划、最先进的设备和不懈的团队合作，我们成功地提高了电力容量，确保了大学隔离区的连续运行。",
                "在不断发展的电气工程世界中，对于可适应、高效和有韧性的解决方案的需求比以往任何时候都更加迫切。我们与鄂州大学的项目合作证明了我们能够迅速有效地应对独特的挑战。",
                "在充满挑战的时期，我们为服务客户和更广泛的社区的承诺始终坚定不移。我们对在这些艰难时刻信任我们的鄂州大学表示感谢，并期待未来更多的合作机会。"
            ],
            "author": "喻迁亦",
            "authorTitle": "喻迁亦",
            "quote": "增强电力容量，遇险而上。湖北西迈电气在疫情期间与社区同进退。",
            "blockquote": "",
            "create_at": "2023年4月25日",
            "blClass": "format-standard-image"
        },
        "5blog": {
            "title": "鄂州滨江二路的街道照明项目",
            "tags": {
                "Street_Lighting": "街道照明",
                "Urban_Infrastructure": "城市基础设施",
                "Urban_Development": "城市发展"
            },
            "description": [
                "作为鄂州滨江防洪生态恢复项目的一部分，我们为鄂州滨江二路的街道照明项目发挥了关键作用，为此我们深感自豪。",
                "我们的参与涵盖了项目服务的全部范围 - 从沟渠开挖、杆子立设、灯具和配件安装，到电缆敷设。我们坚守工程质量的最高标准和对服务诚信的坚定承诺，始终努力为客户提供一流的工程解决方案。",
                "在这个项目的范围内，我们还主动承担了维护和保护客户提供的材料和设施的责任。这包括了诸如现场清理、泥浆处理和水泵工作等任务，确保项目从开始到完成的顺利和成功执行。展望未来，我们的献身精神始终如一，我们已做好为各种工程项目提供专业和可靠的服务和支持的准备。"
            ],
            "author": "喻迁亦",
            "authorTitle": "喻迁亦",
            "quote": "用精准和专业的技能照亮鄂州的街道，为更安全、更明亮的城市空间铺设道路。",
            "blockquote": "",
            "create_at": "2023年4月15日",
            "blClass": "format-standard-image"
        },
        "6blog": {
            "title": "疫情期间的紧急电力维修",
            "tags": {
                "Emergency_Response": "紧急应对",
                "Electrical_Repairs": "电力维修",
                "Social_Responsibility": "社会责任",
                "COVID-19": "新冠疫情"
            },
            "description": [
                "在2020年新冠疫情的艰难时期，我们的公司积极参与紧急电力维修，展现了我们对社会责任的承诺。疫情打乱了日常生活的很多节奏，在这种不稳定的情况中，确保不间断的电力供应比以往任何时候都更为关键。",
                "我们敬业的团队站在第一线，努力应对电力中断并恢复受影响地区的正常状态。这样的行动不仅确保了基本服务的正常运行，而且凸显了我们公司对社区福利的坚定承诺。随着疫情的继续，我们迅速的干预减轻了潜在的困难，增强了社区对我们的专业技术和服务的信任。",
                "在这种前所未有的时期，我们的无与伦比的努力充分体现了公司的核心理念、价值观，以及我们承诺不论遇到多大的挑战，都会勇往直前的决心。"
            ],
            "author": "喻迁亦",
            "authorTitle": "喻迁亦",
            "quote": "在逆境中前行，确保在最黑暗的时刻有光。在2020年的疫情中，我们的承诺始终坚定不移。",
            "blockquote": "",
            "create_at": "2020年5月6日",
            "blClass": "format-standard-image"
        },
        "7blog": {
            "title": "疫情期间鄂州雷山医院电力配电室的翻新",
            "tags": {
                "Medical_Infrastructure": "医疗基础设施",
                "Emergency_Response": "紧急应对",
                "Social_Responsibility": "社会责任",
                "COVID-19": "新冠疫情"
            },
            "description": [
                "在疫情期间，我们的公司响应号召，加入了对抗新冠的行列。我们承担了鄂州雷山医院的电力配电室翻新项目，为医院提供了稳定可靠的电力供应。",
                "我们的专业技术团队经过多轮的研讨和研究，为医院设计了一个全面的电力配电方案。我们严格遵守相关的标准和要求，精心执行了翻新项目。在这个过程中，我们深刻意识到每一位医疗工作者和病人的安全和健康都依赖于可靠的电力供应的支持。",
                "我们的工程师日夜不停地在翻新的前线工作，确保项目的质量和进度。我们的员工始终牢记公司的社会责任，用他们的行动践行我们一直坚守的价值观。",
                "我们将继续关注社会问题，积极响应社会呼吁，并为建设更好的社会做出贡献。"
            ],
            "author": "喻迁亦",
            "authorTitle": "喻迁亦",
            "quote": "在全球危机中，我们的奉献精神得到了体现。在为医疗机构提供电力和点亮希望的信标时，我们的服务承诺得到了体现。",
            "blockquote": "",
            "create_at": "2020年7月18日",
            "blClass": "format-standard-image"
        },
        "8blog": {
            "title": "疫情期间鄂州三医院电力配电室的翻新",
            "tags": {
                "Crisis_Response": "医疗基础设施",
                "Professional_Services": "紧急应对",
                "Medical_Infrastructure": "社会责任",
                "COVID-19": "新冠疫情"
            },
            "description": [
                "在2020年新冠疫情的背景下，我们的公司为鄂州三医院的电力配电室翻新提供了全面的服务，充分体现了企业社会责任感。我们的专业技术团队深入医院现场，通过科学合理的配电计划和精确的施工，为医院提供了稳定可靠的电力供应。",
                "我们的员工克服了疫情带来的各种困难和挑战，展示了抗灾救灾和疫情防控的精神和责任。这个项目的成功实施不仅展示了我们的专业水准和社会责任感，而且加强了我们实践企业社会责任的信念。",
                "我们将继续以更高的标准和更专业的服务为我们的客户和社会创造更大的价值。"
            ],
            "author": "喻迁亦",
            "authorTitle": "喻迁亦",
            "quote": "在疫情严峻时期，我们勇于承担。为医疗机构供电，为社区服务，我们展现了坚定的承诺和韧性。",
            "blockquote": "",
            "create_at": "2020年8月3日",
            "blClass": "format-standard-image"
        },
        "9blog": {
            "title": "传递清凉：我们的项目团队的行动",
            "tags": {
                "Social_Responsibility": "社会责任",
                "Employee_Welfare": "员工福利"
            },
            "description": [
                "在炎炎夏日中，我们公司为中国一冶建设公司的员工发起了一个'传递清凉'的活动。我们为员工提供了清凉饮料和新鲜水果，带来了一丝的凉爽和惊喜。",
                "我们公司一直坚持'以人为本'的服务理念，把客户的需求和体验放在首位。通过这次活动，我们希望传递'爱、关怀、温暖'的企业文化，让更多的人感受到我们的敬业和关心。",
                "我们将继续努力为客户提供更高质量的服务和支持，为构建更好的社会做出贡献。"
            ],
            "author": "喻迁亦",
            "authorTitle": "喻迁亦",
            "quote": "冷却夏日的炎热，我们向员工传递我们的关怀和温暖。秉持我们的'以人为本'的哲学，我们继续追求服务的卓越。",
            "blockquote": "",
            "create_at": "2022年8月7日",
            "blClass": "format-standard-image"
        },
        "10blog": {
            "title": "新冠疫情期间的现场紧急维修",
            "tags": {
                "Emergency_Response": "紧急应对",
                "COVID-19": "新冠疫情",
                "Field_Service": "现场服务",
                "Social_Responsibility": "社会责任"
            },
            "description": [
                "在2021年新冠疫情疫情的艰难时期，我们的团队展现出了卓越的韧性和承诺。我们站在前线，提供现场紧急维修服务，确保在最需要的时候提供不间断的电力供应。",
                "疫情带来了无法预见的挑战，但我们的团队坚守阵地。我们遵循严格的安全协议，不懈地努力，确保关键基础设施的正常运行。这些紧急维修工作涵盖了从修复关键电线问题到恢复和维护关键电气设备的各个方面。",
                "我们的现场服务团队日夜不停地工作，有时在严酷和要求高的环境中。他们的坚定承诺和专业知识确保了最小的停机时间，并保持了基本服务的连续性。",
                "作为一个负责任的组织，我们明白在这样的挑战时期我们的角色。我们有责任提供不间断的服务，确保电力故障不增加我们社区面临的困难。",
                "我们为那些冒着个人风险为大局而工作的团队感到无比自豪。他们的奉献和勇气值得赞扬，他们真正体现了我们公司的精神。",
                "尽管疫情带来的挑战仍在继续，但我们仍然致力于以同样的决心和质量提供我们的服务。我们随时准备面对任何未来的挑战，继续为我们的社区服务。"
            ],
            "author": "喻迁亦",
            "authorTitle": "喻迁亦",
            "quote": "凭借韧性和承诺，我们的团队在疫情期间迅速响应，确保电力供应不间断。在服务的前线，我们随时准备面对任何挑战。",
            "blockquote": "",
            "create_at": "2021年5月21日",
            "blClass": "format-standard-image"
        },
        "11blog": {
            "title": "临空大道公交站台和垃圾桶解决方案的开发",
            "tags": {
                "Infrastructure": "基础设施",
                "Public_Transportation": "公共交通",
                "Urban_Development": "城市发展"
            },
            "description": [
                "我们非常高兴地展示我们对临空大道城市基础设施的全面贡献，包括公交站台和最先进的垃圾桶解决方案。我们在城市发展方面的专业知识在这两个关键项目中得到了体现，强调了我们致力于增强社区体验和环境可持续性的决心。",
                "新设计的临空大道公交站台是现代交通基础设施的典范，旨在为居民和旅行者提供高效、舒适和安全的通勤体验。与此同时，我们的创新垃圾桶解决方案旨在有效处理城市垃圾，支持城市的清洁和环境目标。",
                "我们相信，强大的交通和卫生基础设施在提高城市生活质量中起到了关键作用。我们的团队与当地当局和利益相关者进行了深入的合作，确保这些设施不仅满足当下的需求，而且可以适应未来的需求。",
                "我们始终坚定地致力于塑造便捷、干净和可持续的城市景观。我们与这样的变革性项目的持续关联，证明了我们的愿景和能力。我们急切期待进一步的合作，以增强城市生活。"
            ],
            "author": "喻迁亦",
            "authorTitle": "喻迁亦",
            "quote": "通过先进的交通解决方案，打造和谐的城市体验。",
            "blockquote": "",
            "create_at": "2022年10月26日",
            "blClass": "format-standard-image"
        }
    }
}

export default cn_translation;
