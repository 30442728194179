// import product001Img from '../images/products/product001.png'
// import product002Img from '../images/products/product002.png'
// import product003Img from '../images/products/product003.png'
// import product004Img from '../images/products/product004.png'
// import product005Img from '../images/products/product005.png'
// import product006Img from '../images/products/product006.png'
// import product007Img from '../images/products/product007.png'
import table03 from './table/product3.csv'
import table05 from './table/product5.csv'

import cover000 from '../images/newProducts/cover/cover000.webp'
import cover001 from '../images/newProducts/cover/cover001.webp'
import cover002 from '../images/newProducts/cover/cover002.webp'
import cover003 from '../images/newProducts/cover/cover003.webp'
import cover004 from '../images/newProducts/cover/cover004.webp'
import cover005 from '../images/newProducts/cover/cover005.webp'
import cover006 from '../images/newProducts/cover/cover006.webp'
import cover007 from '../images/newProducts/cover/cover007.webp'
import cover008 from '../images/newProducts/cover/cover008.webp'
import cover009 from '../images/newProducts/cover/cover009.webp'

import product0001Img from '../images/newProducts/product00-01.webp'
import product0002Img from '../images/newProducts/product00-02.webp'
import product0101Img from '../images/newProducts/product01-01.webp'
import product0201Img from '../images/newProducts/product02-01.webp'
import product0301Img from '../images/newProducts/product03-01.webp'
import product0401Img from '../images/newProducts/product04-01.webp'
import product0501Img from '../images/newProducts/product05-01.webp'
import product0502Img from '../images/newProducts/product05-02.webp'
import product0601Img from '../images/newProducts/product06-01.webp'
import product0701Img from '../images/newProducts/product07-01.webp'
import product0702Img from '../images/newProducts/product07-02.webp'
import product0801Img from '../images/newProducts/product08-01.webp'
import product0802Img from '../images/newProducts/product08-02.webp'
import product0901Img from '../images/newProducts/product09-01.webp'
import product0902Img from '../images/newProducts/product09-02.webp'

const Products = [
    {
        id: 0,
        name: "European-style Prefabricated Box-type Substation",
        model: "YB£-12/0.4-£",
        category: "Prefabricated Substation",
        cover: cover000,
        duration: "7 days",
        updateDate: "27 Sept, 2023",
        img: [product0001Img, product0002Img],
        shortDescription: "Widely Used in urban areas, high-rises, industrial sites, highways, oil fields and various public spaces for temporary power.",
        intro: "Widely used in urban grid renovation, residential areas, high-rise buildings, industrial and mining, hotels, shopping malls, airports, railways, oil fields, docks, highways, and other indoor and outdoor locations for temporary power facilities.",
        features: [
            "Integration of high-voltage switchgear, transformers, and low-voltage switchgear, providing a complete set",
            "Comprehensive high and low voltage protection, safe and reliable operation, and easy maintenance",
            "Compact footprint, cost-effective, short production cycle, and easily mobile",
            "Flexible wiring options",
            "Unique Structure: Unique honeycomb double-layer (composite board) shell that is sturdy, heat-insulating, ventilating, aesthetically pleasing, and offers a high level of protection. Shell materials include stainless steel, aluminum alloy, cold-rolled plate, and colored steel plate options",
            "Various Types: General type, villa type, compact type, etc.",
            "The high-voltage ring network cabinet can be equipped with a network automation terminal (FTU) for reliable detection of short circuits and single-phase ground faults. Equipped with 'four remote' functions, facilitating the upgrading of distribution network automation"],
        condition: [
            "Altitude not exceeding 1000m",
            "Ambient temperature: -25℃~+40℃",
            "Relative humidity: Daily average value not exceeding 95%, monthly average value not exceeding 90%",
            "Installation locations: Locations with no risk of fire, explosion, conductive dust, corrosive gases, and severe vibrations. If the conditions exceed these, the user may consult with our company"
        ]
    },
    {
        id: 1,
        name: "American-style Prefabricated Box-type Substation",
        category: "Prefabricated Substation",
        model: "YB27-12",
        cover: cover001,
        img: [product0101Img],
        duration: "10 days",
        updateDate: "27 Sept, 2023",
        shortDescription: "Advanced tech meets local needs in our compact, easy-to-install product, offering low noise, high capacity, and full protection for various settings.",
        intro: "This product is developed by absorbing the latest advanced technology from abroad and combining it with domestic practical situations. It features small size, easy installation and maintenance, low noise, low loss, anti-theft, high overload capacity, and full protection. It is suitable for newly built residential areas, green belts, parks, station hotels, construction sites, airports, etc.",
        features: [
            "Fully insulated, fully sealed, maintenance-free, and ensures personal safety",
            "Compact structure, its volume is only 1/3-1/5 of European substations of the same capacity, with lower height",
            "The split box structure can be adopted to avoid pollution of transformer oil",
            "High-voltage side uses dual fuses for full-range protection, significantly reducing costs",
            "Suitable for both ring networks and terminals, and the cable head can be urgently inserted and removed under a load current of 200A",
            "The box adopts a honeycomb double-layer composite board, which has heat insulation and heat dissipation functions",
            "The low-voltage side is equipped with an electronic phase-loss protector, which can quickly disconnect the main incoming switch when an abnormal voltage occurs in the system",
            "The high-voltage side has oil-immersed load switches or SF6 load switches, which can be electrically upgraded to lay the foundation for distribution network automation",
            "Uses oil-immersed S9 transformers or better-performing S11 series transformers"
        ],
        condition: [
            "Altitude not exceeding 1000m",
            "Ambient temperature: -35℃~+40℃",
            "Relative humidity: Daily average value not exceeding 95%, monthly average value not exceeding 90%",
            "Installation locations: Locations without the risk of fire, explosion, corrosive gases, and well-ventilated areas, ground inclination not exceeding 3°"
        ]
    },
    {
        id: 2,
        name: "Armored Movable Enclosed Switchgear",
        model: "KYN28A-12",
        category: "Switchgear",
        cover: cover002,
        img: [product0201Img],
        duration: "3 days",
        updateDate: "27 Sept, 2023",
        type: "Indoor AC Metal-Clad Mid-mounted Switchgear",
        shortDescription: "Compact, easy-to-install AC metal-clad product with low noise and high capacity, ideal for new residential areas and public spaces.",
        intro: "KYN28A-12 type indoor AC metal-clad mid-mounted switchgear is suitable for three-phase AC systems with a rated voltage of 12kV and a rated frequency of 50Hz. It is used for receiving and distributing electrical energy and for controlling, protecting, and monitoring circuits.",
        features: [
            "The switchgear is designed according to GB3906-91 for armored metal-enclosed switchgear. It is mainly composed of a cabinet and mid-mounted drawable parts (i.e., trolleys). The cabinet is divided into four separate compartments, with an outer shell protection level of IP4X. The protection level between the compartments and when the circuit breaker chamber door is open is IP2X.",
            "It provides overhead inlets and outlets, cable inlets and outlets, and other functional schemes. After being arranged and combined, it forms a complete power distribution system device.",
            "Installation, debugging, and maintenance of this switchgear can be performed from the front. Therefore, it can be back-to-back, form double rows, and be installed against the wall, improving the safety and flexibility of the switchgear and reducing its footprint."
        ],
        condition: [
            "Ambient air temperature: Maximum temperature +40℃, minimum temperature -15℃",
            "Relative humidity: Daily average relative humidity: ≤95%; daily average water vapor pressure does not exceed 2.2KPa; monthly average relative humidity: <90%; monthly average water vapor pressure does not exceed 1.8KPa",
            "Altitude: Below 1000m",
            "Seismic intensity: Not exceeding 8 degrees",
            "The surrounding air should not be significantly polluted by corrosive or flammable gases, water vapor, etc.",
            "No severe vibration locations",
            "If used under conditions beyond those stipulated by GB3906, it should be negotiated between the user and the company."
        ]
    },
    {
        id: 3,
        name: "Low Voltage Switchgear",
        model: "GGD",
        category: "Switchgear",
        cover: cover003,
        img: [product0301Img],
        duration: "3 days",
        updateDate: "27 Sept, 2023",
        shortDescription: "High-capacity AC cabinet for energy conversion and distribution in various sectors; features flexibility and high protection.",
        type: "AC Low Voltage Power Distribution Cabinet",
        intro: "The GGD AC low voltage power distribution cabinet is suitable for power plants, substations, industrial and mining enterprises, and other electricity users with a 50Hz AC system, rated working voltage of 380V, and rated working current of 5000A. It is used for the conversion, distribution, and control of electrical energy for power, lighting, and distribution equipment. The product has high breaking capacity, good dynamic thermal stability, flexible electrical schemes, convenient combination, strong practicality, novel structure, and high protection level. It can be used as an upgraded product for low-voltage switchgear.",
        features: [
            "High breaking capacity and good dynamic thermal stability",
            "Flexible electrical schemes and convenient combinations",
            "Strong practicality, novel structure, and high protection level",
            "Can be used as an upgraded product for low-voltage switchgear"
        ],
        condition: [
            "Ambient air temperature not higher than +40℃ and not lower than -5℃. The average temperature within 24 hours should not exceed +35℃",
            "Indoor installation, the altitude of the installation location should not exceed 2000m",
            "Relative humidity not exceeding 50% at the highest temperature of +40℃, and a larger relative humidity is allowed at lower temperatures (e.g., 90% at +20℃). The impact of condensation due to temperature changes should be considered",
            "The inclination of the equipment when installed should not exceed 5%",
            "The equipment should be installed in a place without severe vibration and impact, and where the electrical components are not subject to corrosion",
            "For special requirements, negotiate with the manufacturer"
        ],
        technicalParameters: table03
    },
    {
        id: 4,
        name: "Low Voltage Reactive Power Compensation Device",
        model: "GGJ",
        cover: cover004,
        img: [product0401Img],
        category: "Switchgear",
        duration: "3 days",
        updateDate: "27 Sept, 2023",
        shortDescription: "Smart, energy-efficient device for reactive power compensation in low-voltage systems, enhancing power factors and minimizing losses .",
        intro: "Designed with the aid of computer technology, our GGJ Low Voltage Reactive Power Compensation Device features intelligent tracking and compensation for reactive power. It's a state-of-the-art, energy-efficient solution that improves power factors and reduces losses in low voltage electrical systems.",
        features: [
            "Intelligent controller",
            "Fully functional",
            "Reliable performance",
            "Automatic compensation modes",
            "Real-time display of power factor",
            "Multiple integrated safety features",
            "Real-time measurements and on-the-hour recording of three-phase voltage, current, and frequency on the transformer's low-voltage side",
            "Active and reactive power",
            "Power factor",
            "Active and reactive energy"],
        technicalParameters: [
            "Rated voltage: 0.38-0.66kV",
            "Rated frequency: 50Hz",
            "Rated capacity: 1-600kvar", "Max allowed current: 1.3 times the rated current",
            "Control circuit: 1-16 circuits",
            "Switching time: 1-150S per time",
            "Operating mode: Automatic, continuous operation"],
    },
    {
        id: 5,
        name: "Low Voltage Withdrawable Switchgear",
        model: "GCS",
        category: "Switchgear",
        cover: cover005,
        img: [product0501Img, product0502Img],
        duration: "3 days",
        updateDate: "27 Sept, 2023",
        shortDescription: "Our GCS Low Voltage Withdrawable Switchgear is well-suited for power plants and high-rises. It offers a comprehensive solution for distributing power effectively.",
        intro: "Our GCS Low Voltage Withdrawable Switchgear is well-suited for power plants, oil and gas facilities, metallurgical plants, textile mills, and high-rise buildings. It offers a comprehensive solution for distributing power effectively.",
        features: [
            "Operates effectively between -5°C and +40°C",
            "Indoor use",
            "Max altitude of 2000m",
            "Relative humidity no more than 50% at highest temperature"
        ],
        technicalParameters: table05,
    },
    {
        id: 6,
        name: "Dry-type Transformer",
        model: "SCB11",
        cover: cover006,
        img: [product0601Img],
        category: "Transformer",
        duration: "7 days",
        updateDate: "27 Sept, 2023",
        shortDescription: "Our dry-type transformers excel in safety and efficiency, ideal for high-rises, airports, ports, and CNC machinery.",
        intro: "Ideal for local lighting, high-rise buildings, airports, ports, and CNC machinery, our dry-type transformers offer safety, efficiency, and ease of use.",
        features: [
            "Safe",
            "Fire-resistant",
            "Pollution-free",
            "Good heat dissipation",
            "Strong overload capacity",
            "Moisture-resistant",
            "Compact",
            "Lightweight",
            "Space-efficient",
            "Low installation cost"
        ],
        technicalParameters: ["Operating frequency: 50 / 60HZ", "No-load current: < 4 %", "Dielectric strength: 20000V / min without puncture", "Insulation grade: F"],
    },
    {
        id: 7,
        name: "Oil-immersed Transformer",
        category: "Transformer",
        cover: cover007,
        img: [product0701Img, product0702Img],
        model: "S11",
        duration: "7 days",
        updateDate: "27 Sept, 2023",
        shortDescription: "Our oil-immersed transformers reliably convert voltage for end-users, built for indoor/outdoor use and harsh conditions.",
        intro: "Our oil-immersed transformers are perfect for transforming voltage, commonly from 10kv or 35kv down to 380V or 220V for end-user applications. Suitable for both indoor and outdoor settings, they are built to withstand harsh conditions.",
        features: ["Efficient", "Durable", "Low maintenance", "Long-lasting"],
        technicalParameters: ["Rated capacity: 50-2000kVA", "Rated voltage: 10KV or 35KV", "Voltage adjustment: Off-circuit or semi-automatic", "Insulation grade: F"],
    },
    {
        id: 8,
        name: "Indoor Voltage Transformer",
        category: "CT&PT",
        cover: cover008,
        img: [product0801Img, product0802Img],
        model: "JDZX9-10",
        duration: "7-15 days",
        updateDate: "17 Jan, 2024",
        shortDescription: "Indoor Epoxy Resin Voltage Transformer, Lightweight and Efficient.",
        intro: "This indoor voltage transformer is an epoxy resin casting full enclosed full insulating product. It is used for voltage measuring, electric energy metering and relay protection. Small size and lightweight, there are secondary terminal box, wiring is convenient.",
        features: ["Indoor Installation", "Wide Temperature Range"],
        technicalParameters: ["Rated insulation level: 12/42/75kV", "Rated frequency: 50/60Hz", "Power factor: COSφ=0.8(lag)", "Standard: GB20840.1(2,3,4)"],
    },
    {
        id: 9,
        name: "LZZW-10 Current Transformer",
        category: "CT&PT",
        cover: cover009,
        img: [product0901Img, product0902Img],
    }
];

export default Products;

