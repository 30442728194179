import React from 'react'
import { Link } from 'react-router-dom'
import er404img from '../../images/error-404.png'
import er409img from '../../images/error-409.webp'
import { useTranslation } from 'react-i18next';

const Error = (props) => {
    const { t } = useTranslation();
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="error-404-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="content clearfix">
                            <div className="error">
                                <img src={props.errorCode === '404' ? er404img : er409img} alt="404" />
                            </div>
                            <div className="error-message">
                                {
                                    props.errorCode === '409' ?
                                        <>
                                            <h3>{t("SomethingWentWrong")}</h3>
                                            <p>{t("ContactAdministratorOrGoBackHome")}</p>
                                            <Link onClick={ClickHandler} to="/home" className="theme-btn"> {t("BacktoHome")}</Link>
                                        </>
                                        : <>
                                            <h3>{t("OopsPageNotFound")}</h3>
                                            <p>{t("SorryNotFound")}</p>
                                            <Link onClick={ClickHandler} to="/home" className="theme-btn"> {t("BacktoHome")}</Link>
                                        </>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Error;