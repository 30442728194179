import React, { useState, useEffect } from 'react'
import products from '../../api/newproduct'
import ProductList from '../ProductList/ProductList'
import ProductCategories from '../ProductCategories/ProductCategories'
import { useTranslation } from 'react-i18next';

function ProductSection({ selectedFromNavibar }) {
    const [selectedCategory, setSelectedCategory] = useState("");
    const { t } = useTranslation();
    useEffect(() => {
        if (selectedFromNavibar !== undefined) {
            setSelectedCategory(selectedFromNavibar);
        }
    }, [selectedFromNavibar]);

    const filteredProducts = products.filter(
        (product) => {
            return product.category === selectedCategory;
        }
    );


    return (
        <div className="product">
            <div className="container">
                <div className="productTitle">{t("ProductOverview")}</div>
                <div className='productContent'>
                    <div className='productCategory col-lg-2'>
                        <ProductCategories onCategorySelect={(category) => setSelectedCategory(category)} selectedFromNavibar={selectedFromNavibar} />
                    </div>
                    <div className="verticalDivider"></div>
                    <div className='productList'>
                        <ProductList products={selectedCategory === "" ? products : filteredProducts} />
                    </div>
                </div>
            </div>
        </div>

    );
}
export default ProductSection
