import qimg1 from '../images/qualification/1.webp'
import qimg2 from '../images/qualification/2.webp'
import qimg3 from '../images/qualification/3.webp'
import qimg4 from '../images/qualification/4.webp'
import qimg5 from '../images/qualification/5.webp'
import qimg6 from '../images/qualification/6.webp'
import qimg7 from '../images/qualification/7.webp'
import qimg8 from '../images/qualification/8.webp'
import qimg9 from '../images/qualification/9.webp'

import ps1img1 from '../images/project-single/1.jpg'
import ps1img2 from '../images/project-single/2.jpg'
import ps1img3 from '../images/project-single/3.jpg'
import ps1img4 from '../images/project-single/4.jpg'
import ps1img5 from '../images/project-single/1.jpg'
import ps1img6 from '../images/project-single/2.jpg'
import ps1img7 from '../images/project-single/3.jpg'
import ps1img8 from '../images/project-single/5.jpg'
import ps1img9 from '../images/project-single/6.jpg'


import psub1img1 from '../images/project-single/p1.jpg'
import psub1img2 from '../images/project-single/p2.jpg'


const Qualification = [
   {
      Id: '1',
      pImg: qimg1,
      ps1img: ps1img1,
      psub1img1: psub1img1,
      psub1img2: psub1img2
   },
   {
      Id: '2',
      pImg: qimg2,
      ps1img: ps1img2,
      psub1img1: psub1img1,
      psub1img2: psub1img2
   },
   {
      Id: '3',
      pImg: qimg3,
      ps1img: ps1img3,
      psub1img1: psub1img1,
      psub1img2: psub1img2
   },
   {
      Id: '4',
      pImg: qimg4,
      ps1img: ps1img4,
      psub1img1: psub1img1,
      psub1img2: psub1img2
   },
   {
      Id: '5',
      pImg: qimg5,
      ps1img: ps1img5,
      psub1img1: psub1img1,
      psub1img2: psub1img2
   },
   {
      Id: '6',
      pImg: qimg6,
      ps1img: ps1img6,
      psub1img1: psub1img1,
      psub1img2: psub1img2
   },
   {
      Id: '7',
      pImg: qimg7,
      ps1img: ps1img7,
      psub1img1: psub1img1,
      psub1img2: psub1img2
   },
   {
      Id: '8',
      pImg: qimg8,
      ps1img: ps1img8,
      psub1img1: psub1img1,
      psub1img2: psub1img2
   },
   {
      Id: '9',
      pImg: qimg9,
      ps1img: ps1img9,
      psub1img1: psub1img1,
      psub1img2: psub1img2
   },

]

export default Qualification;