import { createSlice } from "@reduxjs/toolkit"

const getBrowserLanguage = () => {
    // Try to get the persisted state from localStorage
    const persistedState = localStorage.getItem('persist:root');

    if (persistedState) {
        // If the persisted state exists, parse it and get the languageStatus state
        const state = JSON.parse(persistedState);
        const languageStatus = state.languageStatus;

        if (languageStatus) {
            // If the languageStatus state exists, return it
            return languageStatus.language;
        }
    }

    // Otherwise, use the browser's language setting
    const language = navigator.language || navigator.userLanguage;
    return language.startsWith('zh') ? 'zh' : 'en';
};

const translationSlice = createSlice({
    name: 'languageStatus',
    initialState: {
        language: getBrowserLanguage(),
    },
    reducers: {
        changeLanguage(state, action) {
            state.language = action.payload;
        },
    }
})

const { changeLanguage } = translationSlice.actions

export { changeLanguage }

export default translationSlice.reducer



// const foodStore = createSlice({
//     name: 'foods',
//     initialState: {
//         //  外卖商品列表
//         foodslist: [],
//         // 当前选中的商品分类索引
//         activeIndex: 0,
//         finishLoading: false,
//         cartList: [],
//     },
//     reducers: {
//         // 设置外卖商品列表
//         setFoodsList(state, action) {
//             state.foodslist = action.payload
//         },
//         // 设置当前选中的商品分类索引
//         changeActiveIndex(state, action) {
//             state.activeIndex = action.payload
//         },
//         finishLoading(state, action) {
//             state.finishLoading = action.payload
//         },
//         addCart(state, action) {
//             const { id } = action.payload
//             const index = state.cartList.findIndex(item => item.id === id)
//             if (index === -1) {
//                 state.cartList.push({ ...action.payload, count: 1 })
//             } else {
//                 state.cartList[index].count++
//             }
//         },
//         minusCart(state, action) {
//             const { id } = action.payload
//             const index = state.cartList.findIndex(item => item.id === id)
//             if (index !== -1) {
//                 if (state.cartList[index].count > 1) {
//                     state.cartList[index].count--
//                 } else {
//                     state.cartList.splice(index, 1)
//                 }
//             }
//         },
//         clearCart(state) {
//             state.cartList = []
//         }
//     }
// })

// const { changeActiveIndex, addCart, minusCart, clearCart } = foodStore.actions

// const fetchFoodsList = () => {
//     return async dispatch => {
//         try {
//             const res = await axios.get('http://localhost:3004/takeaway');
//             dispatch(foodStore.actions.setFoodsList(res.data))
//             dispatch(foodStore.actions.finishLoading(true))
//         } catch (error) {
//             console.error(error);
//         }
//     }
// }

// export { fetchFoodsList, changeActiveIndex, addCart, minusCart, clearCart }

// const foodsReducer = foodStore.reducer
// export default foodsReducer