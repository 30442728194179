import React from "react";
import pimg9 from '../../images/partners/img-9.png'
import pimg10 from '../../images/partners/img-10.png'
import pimg11 from '../../images/partners/img-11.png'
import pimg12 from '../../images/partners/img-12.png'
import pimg13 from '../../images/partners/img-13.png'
import pimg14 from '../../images/partners/img-14.png'
import pimg15 from '../../images/partners/img-15.png'
import pimg16 from '../../images/partners/img-16.png'
import SectionTitle from "../SectionTitle/SectionTitle";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const partners = [
    {
        pImg: pimg9,
    },
    {
        pImg: pimg10,
    },
    {
        pImg: pimg11,
    },
    {
        pImg: pimg12,
    },
    {
        pImg: pimg13,
    },
    {
        pImg: pimg14,
    },
    {
        pImg: pimg15,
    },
    {
        pImg: pimg16,
    },
]


const PartnerSection = (props) => {
    const { t } = useTranslation();
    const language = useSelector(state => state.languageStatus.language);
    return (
        <section className="partners-section section-padding pt-175">
            <div className="container">
                <SectionTitle subTitle={t('HONORABLECUSTOMER')} Title={t('Weareworkwithtopbrand')} />
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="partner-grids clearfix">
                            {partners.map((partner, pitem) => (
                                <div className="grid" key={pitem}>
                                    <img src={partner.pImg} alt={language === 'en' ? "partner" : "合作伙伴"} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PartnerSection;
