import React from "react";
import blogs from '../../api/blogs'
import { Link } from "react-router-dom";
import bImg from '../../images/blog/shape.png'
import bImg2 from '../../images/blog/shape2.png'
import SectionTitle from "../SectionTitle/SectionTitle";
import { useTranslation } from 'react-i18next';
import LazyImage from "../lazyLoading/lazyLoading";

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const BlogSection = () => {
    const reversedBlogs = [...blogs].reverse();
    const { t } = useTranslation();
    return (
        <div className="wpo-blog-area-s2 section-padding section-bg">
            <div className="container">
                <SectionTitle subTitle={t('BlogPost')} Title={t('LatestNewsFromBlog')} />
                <div className="wpo-blog-wrap">
                    <div className="row align-items-center">
                        {reversedBlogs.slice(0, 3).map((blog, bl) => (
                            <div className="col-lg-4 col-md-6 col-12" key={bl}>
                                <div className="blog-item">
                                    <div className="blog-img">
                                        <LazyImage src={blog.screens} alt={t(`blog.${blog.id}blog.title`)} className="img img-responsive" />
                                    </div>
                                    <div className="blog-content">
                                        <ul>
                                            <li><a href="blog-single.html">{t(`blog.${blog.id}blog.create_at`)}</a></li>
                                            <li>{t("by")} {t(`blog.${blog.id}blog.author`)}</li>
                                        </ul>
                                        <h2><Link onClick={ClickHandler} to={`/blog-single/${blog.id}`}>{t(`blog.${blog.id}blog.title`)}</Link></h2>
                                        <Link className="arrow-btn" onClick={ClickHandler} to={`/blog-single/${blog.id}`}><i
                                            className="fi flaticon-right-arrow-1"></i></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="shape-1"><img src={bImg} alt="" /></div>
            <div className="shape-2"><img src={bImg2} alt="" /></div>
        </div>
    );
}

export default BlogSection;