import React from 'react'
import { Link } from 'react-router-dom'
import ximaiCore from '../../images/about/ximai-core.webp'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const About4 = () => {
    const language = useSelector(state => state.languageStatus.language);
    const { t } = useTranslation();
    return (
        <section className="wpo-about-section-s4 section-padding pb-120">
            <div className="container">
                <div className="wpo-about-inner">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-5 col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text">
                                <h2>{t('About4.title')}</h2>
                                <p>{t('About4.description1')}</p>
                                <p dangerouslySetInnerHTML={{ __html: t('About4.description2') }}></p>
                                <Link onClick={() => window.scrollTo(10, 500)} className="theme-btn" to="/contact">{t('About4.button')}</Link>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-wrap">
                                <div className="wpo-about-img">
                                    <div className="inner-img">
                                        <img src={ximaiCore} alt={language === 'en' ? "entrepreneurship" : "企业精神"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default About4;

// <h4>Welcome To Ximai</h4>

// <div className="text-sub-wrap">
//     <div className="text-sub-item">
//         <h5>We can provide your the best solutions for your needs</h5>
//     </div>
// </div>