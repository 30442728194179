import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const PageTitle = (props) => {
    const { t } = useTranslation();
    return (
        <section className="page-title">
            <div className="page-inner-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <h2>{t(props.pageTitle)}</h2>
                            <ol className="breadcrumb">
                                <li><Link to="/home">{t('Home')}</Link></li>
                                <li><span>{t(props.pagesub)}</span></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PageTitle;

// import Fade from 'react-reveal/Fade';
// import shape1 from '../../images/page-title-shape-1.png'
// import shape2 from '../../images/page-title-shape-2.png'

// <Fade top>
//                     <div className="page-title-shape-1"></div>
//                 </Fade>
//                 <Fade top>
//                     <div className="page-title-shape-2">
//                         <img src={shape1} alt="" />
//                     </div>
//                 </Fade>
//                 <Fade bottom>
//                     <div className="page-title-shape-3">
//                         <img src={shape2} alt="" />
//                     </div>
//                 </Fade>