import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import { useTranslation } from 'react-i18next';

const VideoModal = () => {
  const { i18n } = useTranslation();
  const [isOpen, setOpen] = useState(false)


  return (
    <React.Fragment>
      {
        i18n.language === 'zh' ?
          <ModalVideo
            channel='custom'
            isOpen={isOpen}
            url="//player.bilibili.com/player.html?aid=439902583&bvid=BV1sL411f7gS&cid=1095241037&p=1"
            onClose={() =>
              setOpen(false)}
          /> :
          <ModalVideo
            channel='youtube'
            autoplay
            isOpen={isOpen}
            videoId="ekqE2rUnFdQ"
            onClose={() =>
              setOpen(false)}
          />
      }
      <div className="video-btn">
        <ul>
          <li>
            <button className="btn-wrap" onClick={() => setOpen(true)}><i className="fi flaticon-play-buttton" aria-hidden="true"></i></button>
          </li>
        </ul>
      </div>
    </React.Fragment>
  )
}

export default VideoModal;

// <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="7Jv48RQ_2gk" onClose={() => setOpen(false)} />

// {
//   isOpen && (
//     <div className="bilibili-modal">
//       <iframe
//         src="//player.bilibili.com/player.html?aid=439902583&bvid=BV1sL411f7gS&cid=1095241037&page=1"
//         scrolling="no"
//         border="0"
//         frameborder="no"
//         framespacing="0"
//         allowfullscreen="true"
//         width="100%"
//         height="400px"
//       ></iframe>
//       <button onClick={() => setOpen(false)}>Close</button>
//     </div>
//   )
// }