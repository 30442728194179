import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom'
import SectionTitle2 from "../SectionTitle2/SectionTitle2";
import Services from '../../api/service'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const settings = {
    dots: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                centerMode: false,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
            }
        }
    ]
};

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const ServiceSection = () => {
    const language = useSelector(state => state.languageStatus.language);
    const { t } = useTranslation();
    return (
        <section className="wpo-service-section section-padding">
            <div className="container">
                <SectionTitle2 subTitle={t('customized')} Title={t('ourProducts')} />
                <div className="row-grid wpo-service-slider owl-carousel">
                    <Slider {...settings}>
                        {Services.map((service, index) => (
                            <div className="grid" key={index}>
                                <div className="wpo-service-item">
                                    <div className="wpo-service-img">
                                        <img src={service.sImg} alt={language === 'en' ? "product image" : "产品图片"} />
                                        <div className="wpo-service-text">
                                            <h2>{t(service.sTitle)}</h2>
                                            <p>{t(service.description)}</p>
                                            <Link onClick={ClickHandler} to="/product" state={{ selectedCategory: service.sLink }} >{t("detail")}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
}

export default ServiceSection;

// <div className="wpo-service-hidden-text">
//     <h2>{service.sTitle}</h2>
//     <p>{service.description}</p>
//     <Link onClick={ClickHandler} to={`/service-single/${service.Id}`}>Details</Link>
//     <div className="top-shape">
//         <div className="shape-1"></div>
//         <div className="shape-2"></div>
//         <div className="shape-3"></div>
//     </div>
//     <div className="bottom-shape">
//         <div className="shape-1"></div>
//         <div className="shape-2"></div>
//         <div className="shape-3"></div>
//     </div>
// </div>