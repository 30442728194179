import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useTranslation } from 'react-i18next';

const MobileCategory = ({ onCategorySelect, categories }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const useStyles = makeStyles({
        drawerPaper: {
            display: 'flex',
            alignItems: 'center', // 垂直居中
            justifyContent: 'center', // 水平居中
            height: '100%', // 设置高度
        },
        button: {

        }
    });

    const classes = useStyles();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    return (
        <div>
            <div className='category-mobile-menu'>
                <Button onClick={handleDrawerOpen}><FilterListIcon />{t("ProductCategories.showcategories")}</Button>
            </div>
            <Drawer anchor="right" open={open} onClose={handleDrawerClose} classes={{ paper: classes.drawerPaper }}>
                <List>
                    <ListItem button onClick={() => { onCategorySelect(''); handleDrawerClose(); }}>
                        <ListItemText primary={t("AllProducts")} />
                    </ListItem>
                    {categories.map((category, index) => (
                        <ListItem button key={index} onClick={() => { onCategorySelect(category); handleDrawerClose(); }}>

                            <ListItemText primary={t(`ProductCategories.${category.replace(/\s*/g, "")}`)} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </div>
    );
};

export default MobileCategory;

// const MobileCategory = ({ onCategorySelect, categories }) => {
//     const [open, setOpen] = useState(false);

//     const useStyles = makeStyles({
//         drawerPaper: {
//             display: 'flex',
//             alignItems: 'center', // 垂直居中
//             justifyContent: 'center', // 水平居中
//             height: '100%', // 设置高度
//         },
//         button: {

//         }
//     });

//     const classes = useStyles();

//     const handleDrawerOpen = () => {
//         setOpen(true);
//     };

//     const handleDrawerClose = () => {
//         setOpen(false);
//     };


//     return (
//         <div>
//             <div className='category-mobile-menu'>
//                 <Button onClick={handleDrawerOpen}><FilterListIcon />Show Categories</Button>
//             </div>
//             <Drawer anchor="right" open={open} onClose={handleDrawerClose} classes={{ paper: classes.drawerPaper }}>
//                 <List>
//                     <ListItem button onClick={() => { onCategorySelect(''); handleDrawerClose(); }}>
//                         <ListItemText primary="All Products" />
//                     </ListItem>
//                     {categories.map((category, index) => (
//                         <ListItem button key={index} onClick={() => { onCategorySelect(category); handleDrawerClose(); }}>

//                             <ListItemText primary={category} />
//                         </ListItem>
//                     ))}
//                 </List>
//             </Drawer>
//         </div>
//     );
// };

// export default MobileCategory;