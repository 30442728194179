import React, { useState, useEffect } from 'react';
import ContentLoader from "react-content-loader"

const MyLoader = ({ width, height }) => (
    <ContentLoader
        speed={2}
        width="100%"
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
    </ContentLoader>
)


function ImageWithLoading({ src, alt, ratio }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            // 获取图片的原始尺寸
            setDimensions({
                width: img.naturalWidth,
                height: img.naturalWidth / ratio,
            });
            setTimeout(() => {
                setIsLoading(false);
                setIsLoaded(true);
            }, 200);
        };

    }, [src]);

    if (isLoading) {
        return <MyLoader width={dimensions.width} height={dimensions.height} />;
    } else {
        return <img className={isLoaded ? 'loaded' : ''} src={src} alt={alt} />;
    }
}

export default ImageWithLoading;