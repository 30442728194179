import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle from '../SectionTitle/SectionTitle';
import tesmnl from '../../images/testimonial/tesmnl.png'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


const Testimonial = () => {

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const { t } = useTranslation();
    const language = useSelector(state => state.languageStatus.language);
    const testimonial = t('testimonial.words', { returnObjects: true });


    return (
        <section className="wpo-testimonial-section section-padding">
            <div className="container">
                <div className="wpo-testimonial-wrap">
                    <div className="row">
                        <div className="col-lg-6">
                            <SectionTitle subTitle={t('testimonial.sectionSubtitle')} Title={t('testimonial.sectionTitle')} />
                            <div className="wpo-testimonial-items">
                                <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} dots={true} arrows={false} autoplay={true}>
                                    {
                                        Object.keys(testimonial).map((key, index) => (
                                            <div className="wpo-testimonial-item" key={index}>
                                                <div className="wpo-testimonial-text">
                                                    <p>“{testimonial[key].Des}”</p>
                                                    <div className="wpo-testimonial-text-btm">
                                                        <span>{testimonial[key].Sub}</span>
                                                        <h3>{testimonial[key].Title}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <div className="testimonial-right-side">
                                <Slider
                                    asNavFor={nav1}
                                    ref={(slider2) => setNav2(slider2)}
                                    slidesToShow={1}
                                    fade={true}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                >
                                </Slider>
                                <div className="testimonial-img">
                                    <img src={tesmnl} alt={language === 'en' ? "Photos with clients" : "客户合影"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;