import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from '../HomePage/HomePage'
import AboutPage from '../AboutPage/AboutPage';
import BlogPage from '../BlogPage/BlogPage'
import BlogDetails from '../BlogDetails/BlogDetails'
import ProductSinglePage from '../ProductSingle/ProductSingle'
import ContactPage from '../ContactPage/ContactPage';
import ErrorPage from '../ErrorPage/ErrorPage';
import ProductSingle from '../ProductPage/ProductPage';


const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path='product-single/:id' element={<ProductSinglePage />} />
          <Route path='blog-single/:id' element={<BlogDetails />} />
          <Route path='blog' element={<BlogPage />} />
          <Route path='blog/:page' element={<BlogPage />} />
          <Route path="blog/search/:page" element={<BlogPage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path="product" element={<ProductSingle />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/404" element={<ErrorPage errorCode={'404'} />} />
          <Route path="/409" element={<ErrorPage errorCode={'409'} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AllRoute;
