import transImg from '../images/service/trans.webp'
import setEquip from '../images/service/setEquip.webp'
import substation from '../images/service/substation.webp'
import irons from '../images/service/irons.webp'

import sImgS1 from '../images/service-single/img-1.jpg'
import sImgS2 from '../images/service-single/img-2.jpg'
import sImgS3 from '../images/service-single/img-3.jpg'
import sImgS4 from '../images/service-single/img-4.jpg'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'




const Services = [
    {
        Id: '1',
        sImg: transImg,
        sImgS: sImgS1,
        sTitle: 'service.transformerTitle',
        sLink: 'Transformer',
        description: 'service.transformerDescription',
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: "'One way to categorize the activities is in terms of the professional's area of expertise such as competitive analysis, corporate strategy the activities'",
        icon: 'flaticon-advisor',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
    },
    {
        Id: '2',
        sImg: setEquip,
        sImgS: sImgS2,
        sTitle: 'service.switchgearTitle',
        sLink: 'Switchgear',
        description: 'service.switchgearDescription',
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: "One way to categorize the activities is in terms of the professional's area of expertise such as competitive analysis, corporate strategy the activities",
        icon: 'flaticon-team',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
    },
    {
        Id: '3',
        sImg: substation,
        sImgS: sImgS3,
        sTitle: 'service.substationTitle',
        sLink: 'Prefabricated Substation',
        description: 'service.substationDescription',
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: "'One way to categorize the activities is in terms of the professional's area of expertise such as competitive analysis, corporate strategy the activities'",
        icon: 'flaticon-taxes',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
    },
    {
        Id: '4',
        sImg: irons,
        sImgS: sImgS4,
        sTitle: 'service.accessoriesTitle',
        sLink: 'Accessories',
        description: 'service.accessoriesDescription',
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: "'One way to categorize the activities is in terms of the professional's area of expertise such as competitive analysis, corporate strategy the activities'",
        icon: 'flaticon-tax',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
    }
]

export default Services;


// {
//     Id: '5',
//         sImg: irons,
//             sImgS: sImgS4,
//                 sTitle: 'Metering Transformer',
//                     description: 'Need description.',
//                         des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
//                             des3: "'One way to categorize the activities is in terms of the professional's area of expertise such as competitive analysis, corporate strategy the activities'",
//                                 icon: 'flaticon-tax',
//                                     ssImg1: sSingleimg1,
//                                         ssImg2: sSingleimg2,
//     }