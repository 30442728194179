// images 600*771
import blogImg1 from "../images/blog/img-1-1.webp";
import blogImg3 from "../images/blog/img-3-1.webp";
import blogImg4 from "../images/blog/img-4-1.webp";
import blogImg5 from "../images/blog/img-5-1.webp";
import blogImg6 from "../images/blog/img-6-1.webp";
import blogImg7 from "../images/blog/img-7-1.webp";
import blogImg8 from "../images/blog/img-8-1.webp";
import blogImg9 from "../images/blog/img-9-1.webp";
import blogImg10 from "../images/blog/img-10-1.webp";
import blogImg11 from "../images/blog/img-11-1.webp";
import blogImg12 from "../images/blog/img-12-1.webp";
// images 868*514
import blogSingleImg1 from "../images/blog/blog-single/img-1-1.webp";
import blogSingleImg3 from "../images/blog/blog-single/img-3-1.webp";
import blogSingleImg4 from "../images/blog/blog-single/img-4-1.webp";
import blogSingleImg5 from "../images/blog/blog-single/img-5-1.webp";
import blogSingleImg6 from "../images/blog/blog-single/img-6-1.webp";
import blogSingleImg7 from "../images/blog/blog-single/img-7-1.webp";
import blogSingleImg8 from "../images/blog/blog-single/img-8-1.webp";
import blogSingleImg9 from "../images/blog/blog-single/img-9-1.webp";
import blogSingleImg10 from "../images/blog/blog-single/img-10-1.webp";
import blogSingleImg11 from "../images/blog/blog-single/img-11-1.webp";
import blogSingleImg12 from "../images/blog/blog-single/img-12-1.webp";
// image content 434*514

import blogContentImg10one from "../images/blog/blog-content/img-10-2.webp";
import blogContentImg10two from "../images/blog/blog-content/img-10-3.webp";
import blogContentImg10three from "../images/blog/blog-content/img-10-4.webp";
import blogContentImg12one from "../images/blog/blog-content/img-12-2.webp";
import blogContentImg12two from "../images/blog/blog-content/img-12-3.webp";
import blogContentImg12three from "../images/blog/blog-content/img-12-4.webp";
// image content 600*771

// image content 868*514
import blogContentImg1 from "../images/blog/blog-content/img-1-1.webp";
import blogContentImg3 from "../images/blog/blog-content/img-3-2.webp";
import blogContentImg6 from "../images/blog/blog-content/img-6-2.webp";
import blogContentImg8 from "../images/blog/blog-content/img-8-2.webp";
import blogContentImg9 from "../images/blog/blog-content/img-9-2.webp";
import blogContentImg11 from "../images/blog/blog-content/img-11-2.webp";

const blogs = [
    {
        id: '1',
        title: "1blog.title",
        tags: "1blog.tags",
        screens: blogImg1,
        numImgContent: 1,
        imgContent: [blogContentImg1],
        description: "1blog.description",
        author: '1blog.author',
        authorTitle: '1blog.authorTitle',
        quote: "1blog.quote",
        blockquote: '1blog.blockquote',
        create_at: '1blog.create_at',
        blogSingleImg: blogSingleImg1,
        blClass: '1blog.blClass'
    },
    {
        id: '2',
        title: "2blog.title",
        tags: "2blog.tags",
        screens: blogImg12,
        numImgContent: 3,
        imgContent: [blogContentImg12one, blogContentImg12two, blogContentImg12three],
        description: "2blog.description",
        author: '2blog.author',
        authorTitle: '2blog.authorTitle',
        quote: "2blog.quote",
        blockquote: '2blog.blockquote',
        create_at: '2blog.create_at',
        blogSingleImg: blogSingleImg12,
        blClass: '2blog.blClass',
    },
    {
        id: '3',
        title: "3blog.title",
        tags: "3blog.tags",
        screens: blogImg3,
        numImgContent: 1,
        imgContent: [blogContentImg3],
        description: "3blog.description",
        author: '3blog.author',
        authorTitle: '3blog.authorTitle',
        quote: "3blog.quote",
        blockquote: '3blog.blockquote',
        create_at: '3blog.create_at',
        blogSingleImg: blogSingleImg3,
        blClass: '3blog.blClass',
    },
    {
        id: '4',
        title: "4blog.title",
        tags: "4blog.tags",
        screens: blogImg4,
        numImgContent: 0,
        imgContent: [],
        description: "4blog.description",
        author: '4blog.author',
        authorTitle: '4blog.authorTitle',
        quote: "4blog.quote",
        blockquote: '4blog.blockquote',
        create_at: '4blog.create_at',
        blogSingleImg: blogSingleImg4,
        blClass: '4blog.blClass',
    },
    {
        id: '5',
        title: "5blog.title",
        tags: "5blog.tags",
        screens: blogImg5,
        numImgContent: 0,
        imgContent: [],
        description: "5blog.description",
        author: '5blog.author',
        authorTitle: '5blog.authorTitle',
        quote: "5blog.quote",
        blockquote: '5blog.blockquote',
        create_at: '5blog.create_at',
        blogSingleImg: blogSingleImg5,
        blClass: '5blog.blClass',
    },
    {
        id: '6',
        title: "6blog.title",
        tags: "6blog.tags",
        screens: blogImg6,
        numImgContent: 1,
        imgContent: [blogContentImg6],
        description: "6blog.description",
        author: '6blog.author',
        authorTitle: '6blog.authorTitle',
        quote: "6blog.quote",
        blockquote: '6blog.blockquote',
        create_at: '6blog.create_at',
        blogSingleImg: blogSingleImg6,
        blClass: '6blog.blClass',
    },
    {
        id: '7',
        title: "7blog.title",
        tags: "7blog.tags",
        screens: blogImg7,
        numImgContent: 0,
        imgContent: [],
        description: "7blog.description",
        author: '7blog.author',
        authorTitle: '7blog.authorTitle',
        quote: "7blog.quote",
        blockquote: '7blog.blockquote',
        create_at: '7blog.create_at',
        blogSingleImg: blogSingleImg7,
        blClass: '7blog.blClass',
    },
    {
        id: '8',
        title: "8blog.title",
        tags: "8blog.tags",
        screens: blogImg8,
        numImgContent: 1,
        imgContent: [blogContentImg8],
        description: "8blog.description",
        author: '8blog.author',
        authorTitle: '8blog.authorTitle',
        quote: "8blog.quote",
        blockquote: '8blog.blockquote',
        create_at: '8blog.create_at',
        blogSingleImg: blogSingleImg8,
        blClass: '8blog.blClass',
    },
    {
        id: '9',
        title: "9blog.title",
        tags: "9blog.tags",
        screens: blogImg9,
        numImgContent: 1,
        imgContent: [blogContentImg9],
        description: "9blog.description",
        author: '9blog.author',
        authorTitle: '9blog.authorTitle',
        quote: "9blog.quote",
        blockquote: '9blog.blockquote',
        create_at: '9blog.create_at',
        blogSingleImg: blogSingleImg9,
        blClass: '9blog.blClass',
    },
    {
        id: '10',
        title: "10blog.title",
        tags: "10blog.tags",
        screens: blogImg10,
        numImgContent: 3,
        imgContent: [blogContentImg10one, blogContentImg10two, blogContentImg10three],
        description: "10blog.description",
        author: '10blog.author',
        authorTitle: '10blog.authorTitle',
        quote: "10blog.quote",
        blockquote: '10blog.blockquote',
        create_at: '10blog.create_at',
        blogSingleImg: blogSingleImg10,
        blClass: '10blog.blClass',
    },
    {
        id: '11',
        title: "11blog.title",
        tags: "11blog.tags",
        screens: blogImg11,
        numImgContent: 1,
        imgContent: [blogContentImg11],
        description: "11blog.description",
        author: '11blog.author',
        authorTitle: '11blog.authorTitle',
        quote: "11blog.quote",
        blockquote: '11blog.blockquote',
        create_at: '11blog.create_at',
        blogSingleImg: blogSingleImg11,
        blClass: '11blog.blClass',
    },
];
export default blogs;

// {
//     id: '1',
//         title: 'Securing Multiple Major Projects Reflects Our Strength and Credibility',
//             screens: blogImg1,
//                 description: 'Consectetur adipiscing elit. Purusout phasellus malesuada lectus.',
//                     author: 'Marilou Kelleher',
//                         authorTitle: 'Sineor Consultant',
//                             quote: 'Unwavering commitment and unparalleled expertise: a testament to our expansive accomplishments across Ezhou and beyond. Each project reflects not just our work, but our promise to power the future.',
//                                 blockquote: '2',
//                                     create_at: '25 Dec, 2020',
//                                         blogSingleImg: blogSingleImg1,
//                                             blClass: 'format-standard-image',
//     },