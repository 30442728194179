import React from 'react'
import simg from '../../images/mission-shape.png'
import AdjustIcon from '@material-ui/icons/Adjust';
import { useTranslation } from 'react-i18next';

const MissionVission = (props) => {

    const { t } = useTranslation();
    const landmarks = t('missionVissionPage.landmarks', { returnObjects: true });

    return (
        <section className="wpo-mission-vission-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-vission-item">
                            <div className="wpo-vission-text">
                                <h2>{t('missionVissionPage.transformersSubstationsTitle')}</h2>
                                <h5>{t('missionVissionPage.clienteleSatisfaction')}</h5>
                                <p>{t('missionVissionPage.currentCustomersDescription')}</p>
                                <p>{t('missionVissionPage.operationalLandmarksDescription')}</p>
                                <br></br>
                                {/* 展示地标列表 */}
                                {
                                    landmarks.map((landmark, index) => (
                                        <p key={index}><AdjustIcon style={{ color: "#DE3838", margin: "0 10px 4px" }} />{landmark}</p>
                                    ))
                                }
                                <br></br>
                                <p>{t('missionVissionPage.customTransformersInfo')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-mission-item">
                            <div className="wpo-mission-text">
                                <h2>{t('missionVissionPage.qualityCornerstoneTitle')}</h2>
                                <p>{t('missionVissionPage.businessTrustSubtitle')}</p>
                                <h5>{t('missionVissionPage.ximaiQualityImportance')}</h5>
                                <h5>{t('missionVissionPage.safetyFirstQualityOriented')}</h5>
                            </div>
                            <div className="shape-1"></div>
                            <div className="shape-2"><img src={simg} alt="shape" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MissionVission;

// <div className="col-lg-6 col-md-12 col-12">
//     <div className="wpo-mission-item">
//         <div className="wpo-mission-text">
//             <h2>Our Quality</h2>
//             <p>Quality forms the cornerstone of our brand.</p>
//             <h5>Ximai adheres to ISO 9001, ISO 14001, and OHSAS 18001 standards, and its low-voltage equipment has 3C certification. All products have passed the national quality supervision and inspection tests. With trustworthy services and superior quality, the reputation of Ximai Electric's products continues to rise.</h5>
//         </div>
//         <div className="shape-1"></div>
//         <div className="shape-2"><img src={simg} alt="" /></div>
//     </div>
// </div>
// <div className="col-lg-6 col-md-12 col-12">
//                         <div className="wpo-vission-item">
//                             <div className="wpo-vission-text">
//                                 <h2>Our Reputation</h2>
//                                 <h5>Building remarkable success through a solid reputation.</h5>
//                                 <p>As the company's main products consistently receive certifications from national testing institutions and pass the National Grid's qualified supplier capacity audit, Ximai has achieved considerable success in winning bids for various National Grid projects since 2006. With 'Quality First, Customer Supreme' as its motto, the company has consecutively won the 'Contract-abiding and Trustworthy' enterprise honor in Hubei province and Ezhou city for several years. Additionally, the Ximai Electric logo has been awarded as a trademark in both Hubei province and Ezhou city.</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-lg-6 col-md-12 col-12">
//                         <div className="wpo-mission-item">
//                             <div className="wpo-mission-text">
//                                 <h2>Our Future</h2>
//                                 <p>The company is propelling its growth by fostering innovation.</p>
//                                 <h5>Ximai leverages its talent and technical advantages to make significant strides in energy conservation, technological innovation, and market development. At the same time, it has established collaborative relationships with numerous domestic universities and research institutions, propelling the company's continuous advancement in intelligent manufacturing and the development of high-end technologies for new products.</h5>
//                             </div>
//                             <div className="shape-1"></div>
//                             <div className="shape-2"><img src={simg} alt="" /></div>
//                         </div>
//                     </div>