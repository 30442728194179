import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3';
import PageTitle from '../../components/pagetitle/PageTitle'
import Products from '../../components/Products/Products'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer2 from '../../components/footer2/Footer2';
import { useLocation } from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBounday';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const ProductSingle = () => {
    let { state } = useLocation();
    const selectedFromNavibar = state?.selectedCategory;
    const language = useSelector(state => state.languageStatus.language);
    const title = language === 'en' ? 'Ximai Electric Product' : '西迈电气产品中心';
    const description = language === 'en' ? 'Explore our comprehensive product center at Ximai Electric, featuring a wide array of electrical equipment designed for a variety of applications. Our product offerings include European and American style prefabricated box-type substations, widely used in urban grid transformations, residential areas, high-rise buildings, airports, and more. The YB27-12 American style prefabricated box-type substation, perfect for 10KV ring network power supply systems, highlights our versatility in power control and protection. We also offer KYN28A-12 armored removable enclosed switchgear, GGD type low-voltage distribution cabinets, GGJ low-voltage reactive power intelligent compensation devices, GCS type low-voltage withdrawable switchgear, and both dry-type and oil-immersed transformers, catering to varied industry needs from manufacturing to high-rise constructions. Our indoor voltage transformers and LZZW-10 type current transformers are designed for durability and efficiency in diverse environmental conditions, ensuring reliable power transformation and distribution for every scenario.' : '西迈电气提供多样化的电气设备，满足各种应用需求。我们的产品中心包括欧式和美式预装式箱式变电站，广泛应用于城市电网改造、住宅小区、高层建筑、机场等场所。YB27-12系列美式预装式箱式变电站特别适用于10KV环网供电系统，展现我们在电力控制和保护方面的多样性。此外，我们还提供KYN28A-12型铠装移开式封闭开关设备、GGD型低压配电柜、GGJ低压无功智能补偿装置、GCS型低压抽出式开关柜，以及干式和油浸式变压器，满足制造业至高层建筑等各行业需求。我们的室内电压互感器和LZZW-10型电流互感器设计轻便高效，适应多种环境条件，确保可靠的电力转换和分配。';

    return (
        <ErrorBoundary>
            <Fragment>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                </Helmet>
                <Navbar3 />
                <PageTitle pageTitle={'Products'} pagesub={'Product'} />
                <Products selectedFromNavibar={selectedFromNavibar} />
                <Footer2 />
                <Scrollbar />
            </Fragment>
        </ErrorBoundary>

    )
};
export default ProductSingle;

// import Subscribe from '../../components/Subscribe/Subscribe';
// <Subscribe sbClass={'wpo-subscribe-section-s2 pt-0'} />

