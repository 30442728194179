import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3';
import PageTitle from '../../components/pagetitle/PageTitle'
import Contactpage from '../../components/Contactpage/Contactpage'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Logo from '../../images/logo.png'
import Footer2 from '../../components/footer2/Footer2';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const ContactPage = () => {
    const language = useSelector(state => state.languageStatus.language);
    const title = language === 'en' ? 'Contact Ximai Electric' : '联系西迈电气';
    const description = language === 'en' ? "Reach out to Ximai Electric for top-notch electrical solutions tailored to your needs. Located in Hubei Province at Yanji Industrial Park, Ezhou, we're always ready to connect with you. Email us at hbxmdq@163.com or contact us via WeChat/WhatsApp/Skype at +86 13476184091. For direct queries, call us at 0711-5921007 or fax at 0711-5921066, and for urgent assistance, dial 15926013777. Utilize our online form to provide detailed information for personalized product solutions, and let us know the best time to reach you. Whether you have specific requirements or general inquiries, our team is committed to responding promptly and efficiently." : '欢迎联系西迈电气，获取专业定制的电气解决方案。我们位于湖北省鄂州市燕矶镇燕矶金刚石工业园区燕沙路1号，随时期待与您的沟通。可通过邮箱hbxmdq@163.com与我们联系，或通过微信/Whatsapp/Skype：+86 13476184091直接交流。如需电话咨询，请拨打座机：0711-5921007，传真号码为：0711-5921066，紧急联系电话：15926013777。使用我们的在线表单提供详细信息以获取个性化产品方案，告诉我们您方便接听电话的时间。无论您有特殊需求还是一般咨询，我们的团队都致力于及时、高效地回应您的每一个问题。';

    return (
        <Fragment>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <Navbar3 Logo={Logo} />
            <PageTitle pageTitle={'ContactUs'} pagesub={'Contact'} />
            <Contactpage />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    )
};
export default ContactPage;

