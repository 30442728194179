import React from 'react';
import Products from '../../api/newproduct'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const RelatedProduct = (props) => {

    const [category, id] = [props.category, props.id]
    const navigate = useNavigate();
    const { t } = useTranslation();

    const related = Products.filter((product) => {
        return product.category === category && product.id !== id
    })

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className="wpo-project-single-item related-project">
            <div className="wpo-project-single-title">
                <h3>{t("RelatedProducts")}</h3>
            </div>
            <section className="wpo-shop-section">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="shop-grids clearfix">
                                {related.length > 0 &&
                                    related.slice(0, 3).map((product, index) => (
                                        <div className="grid" key={index}>
                                            <div className="img-holder">
                                                <img src={product.cover} alt={t(`products.${product.id}.name`)} />
                                            </div>
                                            <div className="details">
                                                <h3><Link onClick={ClickHandler} to={`/product-single/${product.id}`}>{t(`products.${product.id}.name`)}</Link></h3>
                                                <span>{t(`products.${product.id}.shortDescription`)}</span>
                                                <div className="add-to-cart">
                                                    <button
                                                        data-bs-toggle="tooltip"
                                                        data-bs-html="true"
                                                        title="Add to Cart"
                                                        onClick={() => {
                                                            ClickHandler();
                                                            navigate(`/product-single/${product.id}`);
                                                        }}
                                                    >
                                                        {t("detail")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};
export default RelatedProduct;

