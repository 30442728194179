import qimg1 from '../images/patents/image10.webp'
import qimg2 from '../images/patents/image11.webp'
import qimg3 from '../images/patents/image12.webp'
import qimg4 from '../images/patents/image13.webp'
import qimg5 from '../images/patents/image14.webp'
import qimg6 from '../images/patents/image15.webp'
import qimg7 from '../images/patents/image16.webp'
import qimg8 from '../images/patents/image17.webp'
import qimg9 from '../images/patents/image18.webp'
import qimg10 from '../images/patents/image19.webp'
import qimg11 from '../images/patents/image20.webp'
import qimg12 from '../images/patents/image21.webp'
import qimg13 from '../images/patents/image22.webp'
import qimg14 from '../images/patents/image23.webp'
import qimg15 from '../images/patents/image24.webp'
import qimg16 from '../images/patents/image25.webp'
import qimg17 from '../images/patents/image26.webp'
import qimg18 from '../images/patents/image27.webp'
import qimg19 from '../images/patents/image28.webp'

import ps1img1 from '../images/project-single/1.jpg'
import ps1img2 from '../images/project-single/2.jpg'
import ps1img3 from '../images/project-single/3.jpg'
import ps1img4 from '../images/project-single/4.jpg'
import ps1img5 from '../images/project-single/1.jpg'
import ps1img6 from '../images/project-single/2.jpg'
import ps1img7 from '../images/project-single/3.jpg'
import ps1img8 from '../images/project-single/5.jpg'
import ps1img9 from '../images/project-single/6.jpg'

import psub1img1 from '../images/project-single/p1.jpg'
import psub1img2 from '../images/project-single/p2.jpg'


const Patents = [
   {
      Id: '1',
      pImg: qimg1,
      ps1img: ps1img1,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Appearance Patent',
      subTitle: 'Distribution Transformer',
   },
   {
      Id: '2',
      pImg: qimg2,
      ps1img: ps1img2,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'Power Distribution Box P1',
   },
   {
      Id: '3',
      pImg: qimg3,
      ps1img: ps1img3,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'Power Distribution Box P2',
   },
   {
      Id: '4',
      pImg: qimg4,
      ps1img: ps1img4,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'Low Voltage Switch Cabinet P1',
   },
   {
      Id: '5',
      pImg: qimg5,
      ps1img: ps1img5,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'Low Voltage Switch Cabinet P2',
   },
   {
      Id: '6',
      pImg: qimg6,
      ps1img: ps1img6,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'Transformer Cabinet P1',
   },
   {
      Id: '7',
      pImg: qimg7,
      ps1img: ps1img7,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'Transformer Cabinet P2',
   },
   {
      Id: '8',
      pImg: qimg8,
      ps1img: ps1img8,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'Lightning Protection Type Transformer Cabinet P1',
   },
   {
      Id: '9',
      pImg: qimg9,
      ps1img: ps1img9,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'Lightning Protection Type Transformer Cabinet P2',
   },
   {
      Id: '10',
      pImg: qimg10,
      ps1img: ps1img1,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'waterproof type Box type substation p1',
   },
   {
      Id: '11',
      pImg: qimg11,
      ps1img: ps1img1,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'waterproof type Box type substation p2',
   },
   {
      Id: '12',
      pImg: qimg12,
      ps1img: ps1img1,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'Shock absorbing type transformer p1',
   },
   {
      Id: '13',
      pImg: qimg13,
      ps1img: ps1img1,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'Shock absorbing type transformer p2',
   },
   {
      Id: '14',
      pImg: qimg14,
      ps1img: ps1img1,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'Detachable type Transformer bottom frame p1',
   },
   {
      Id: '15',
      pImg: qimg15,
      ps1img: ps1img1,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'Detachable type Transformer bottom frame p2',
   },
   {
      Id: '16',
      pImg: qimg16,
      ps1img: ps1img1,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'Cooling structure of new dry-type transformer p1',
   },
   {
      Id: '17',
      pImg: qimg17,
      ps1img: ps1img1,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'Cooling structure of new dry-type transformer p2',
   },
   {
      Id: '18',
      pImg: qimg18,
      ps1img: ps1img1,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'Assembled multifunctional low voltage distribution box p1',
   },
   {
      Id: '19',
      pImg: qimg19,
      ps1img: ps1img1,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Utility Model Patent',
      subTitle: 'Assembled multifunctional low voltage distribution box p2',
   }
]

export default Patents;