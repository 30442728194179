import React from 'react';
import { Link } from 'react-router-dom';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import LazyImage from '../lazyLoading/lazyLoading';

function ProductList({ products }) {
    const { t } = useTranslation();
    const areCategoriesTheSame = products.every(product => product.category === products[0].category);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };


    return (
        <section className="wpo-productList-section">
            <Box display="flex" alignItems="center" marginBottom={'30px'}>
                <DoubleArrowIcon fontSize='large' />
                <h3 className="list-title" style={{ marginBottom: '0px' }}>
                    {areCategoriesTheSame ? t(`ProductCategories.${products[0].category.split(" ").join("")}`) : t("AllProducts")}
                </h3>
            </Box>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="product-grids clearfix">
                            {
                                products.map((product, index) => (
                                    <div className="grid-item" key={index}>
                                        <div className="img-holder" >
                                            <Link onClick={ClickHandler} to={`/product-single/${product.id}`}>
                                                {
                                                    index >= 5 ? <img src={product.cover} alt={t(`products.${product.id}.name`)} /> : <LazyImage src={product.cover} alt={t(`products.${product.id}.name`)} />
                                                }

                                            </Link>

                                        </div>
                                        <div className="details">
                                            <h3><Link onClick={ClickHandler} to={`/product-single/${product.id}`}>{t(`products.${product.id}.name`)}</Link></h3>
                                            <span>
                                                {t(`products.${product.id}.shortDescription`)}
                                            </span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProductList;










// <div className="pagination-wrapper pagination-wrapper-center">
//     <ul className="pg-pagination">
//         <li>
//             <a href="#" aria-label="Previous">
//                 <i className="ti-angle-left"></i>
//             </a>
//         </li>
//         <li className="active"><a href="#">1</a></li>
//         <li><a href="#">2</a></li>
//         <li><a href="#">3</a></li>
//         <li>
//             <a href="#" aria-label="Next">
//                 <i className="ti-angle-right"></i>
//             </a>
//         </li>
//     </ul>
// </div>



// function ProductList({ products }) {
//     const useStyles = makeStyles({
//         cardRoot: {
//             width: 250,        // 固定宽度
//             height: 350,       // 固定高度
//             margin: '10px'     // 固定间距
//         }
//     });

//     const classes = useStyles();
//     const areCategoriesTheSame = products.every(product => product.category === products[0].category);

//     return (
//         <div style={{
//             marginLeft: '50px', marginRight: 'auto', marginBottom: '100px'
//         }}>
//             <h3 style={{ paddingBottom: '10px' }}>{`>`} {areCategoriesTheSame ? products[0].category : `All Products`}</h3>
//             <Grid container spacing={2} justifyContent="flex-start"> {/* 使用 Grid 系统，开始从左侧排列 */}
//                 {products.map(product => (
//                     <Grid item key={product.id} > {/* 设置不同屏幕尺寸下的列宽 */}
//                         <Card key={product.id} className={classes.cardRoot}>
//                             <CardActionArea>
//                                 <CardMedia
//                                     component="img"
//                                     height="180"
//                                     image={product.imgLink[0]}
//                                     alt=""
//                                 />
//                                 <CardContent>
//                                     <Typography gutterBottom variant="h6" component="div">
//                                         {product.name}
//                                     </Typography>
//                                     <Typography variant="body2" color="textSecondary">
//                                         Something
//                                     </Typography>
//                                 </CardContent>
//                             </CardActionArea>
//                             <CardActions>
//                                 <Button size="small" color="primary">
//                                     Learn More
//                                 </Button>
//                             </CardActions>
//                         </Card>

//                     </Grid>
//                 ))}
//             </Grid>
//         </div>
//     );
// }

// export default ProductList;







// const ServiceSectionS3 = () => {
//     return (
//         <section className="wpo-service-section-s4 section-padding">
//             <div className="container">
//                 <SectionTitle subTitle={'Our Services'} Title={'What We Do?'} />
//                 <div className="row">
//                     {Services.map((service, srv) => (
//                         <div className="col-lg-6 col-md-12 col-12" key={srv}>
//                             <div className="wpo-service-item">
//                                 <div className="wpo-service-text">
//                                     <div className="service-icon">
//                                         <i className={`fi ${service.icon}`}></i>
//                                     </div>
//                                     <div className="service-content">
//                                         <h2>{service.sTitle}</h2>
//                                         <p>{service.description}</p>
//                                         <Link onClick={ClickHandler} to={`/service-single/${service.Id}`}>Details</Link>
//                                     </div>
//                                 </div>
//                                 <div className="shape-1"><img src={smg1} alt="" /></div>
//                                 <div className="shape-2"><img src={smg2} alt="" /></div>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </section>
//     );
// }

// export default ServiceSectionS3;