import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Qualification from '../../api/qualification'
import Patents from "../../api/patents";
import SectionTitle2 from "../SectionTitle2/SectionTitle2";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LazyImage from "../lazyLoading/lazyLoading";

const settings = {
    dots: false,
    arrows: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};


const ProjectSection = (props) => {
    // const contents = [...Qualifications, ...Patents]
    const { t } = useTranslation();
    const language = useSelector(state => state.languageStatus.language);
    return (
        <section className={`wpo-project-section section-padding ${props.pClass}`}>
            <div className="container">
                <SectionTitle2 subTitle={t('QUALITYASSURANCE')} Title={t('QualificationCertificates')} />
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-xs-12 sortable-project">
                        <div className="wpo-project-container project-slider owl-carousel">
                            <Slider {...settings}>
                                {Qualification.map((qualification, idx) => (
                                    <div className="grid" key={idx}>
                                        <div className="wpo-project-item">
                                            <LazyImage src={qualification.pImg} alt={language === 'en' ? "qualification certificate" : "荣誉证书"} className="img img-responsive" />
                                            <div className="wpo-project-text">
                                                <h3>{t(`Qualification.${qualification.Id}.title`)}</h3>
                                                <span>{t(`Qualification.${qualification.Id}.subTitle`)}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {Patents.map((patent, idx) => (
                                    <div className="grid" key={idx}>
                                        <div className="wpo-project-item">
                                            <LazyImage src={patent.pImg} alt={language === 'en' ? "patents" : "专利"} className="img img-responsive" />
                                            <div className="wpo-project-text">
                                                <h3>{t(`Patents.${patent.Id}.title`)}</h3>
                                                <span>{t(`Patents.${patent.Id}.subTitle`)}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProjectSection;