import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const About = (props) => {
    const language = useSelector(state => state.languageStatus.language);
    const { t } = useTranslation();
    const description = t("AboutHome.description").split('|');
    return (
        <section className={`wpo-about-section section-padding ${props.abClass}`}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <img src={props.abimg} alt={language === 'en' ? "entrepreneurship" : "企业精神"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">

                            <h2>{t('AboutHome.heroTitle1')}
                                <b><span> {t('AboutHome.heroTitleHighlight1')} </span></b>
                                {t('AboutHome.heroTitle2')}
                                <b> <span>{t('AboutHome.heroTitleHighlight2')}</span> {t('AboutHome.heroTitle3')} </b>
                                {t('AboutHome.heroTitle4')}
                                <b> {t('AboutHome.heroTitleHighlight3')}</b>
                            </h2>
                            <p>{description[0]}<br /><br />
                                {description[1]}</p>
                            <Link onClick={ClickHandler} className="theme-btn" to="/about">{t('AboutHome.exploreBtn')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default About;

