import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import BlogSidebar from '../BlogSidebar/BlogSidebar.js'
import VideoModal from '../ModalVideo/VideoModal'
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import blogs from '../../api/blogs'
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import i18next from 'i18next';
import ImageWithLoading from '../loading/ImageWithLoading.js'
import { useSelector } from 'react-redux';

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const postsPerPage = 3;

function BlogList(props) {
    const [blogsSelected, setBlogsSelected] = useState(blogs)
    const { t } = useTranslation();
    const language = useSelector(state => state.languageStatus.language);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [keyword, setKeyword] = useState(searchParams.get('keyword'))
    const { page } = useParams();
    const [pageNum, setPageNum] = useState(page)

    // 一些关于page的参数：分页，分页组件，页面显示的blog的开始index和结束index
    const paginationElement = [];
    const [startIndex, endIndex] = [(pageNum - 1) * 3, pageNum * 3]
    const totalPages = Math.ceil(blogsSelected.length / postsPerPage)

    useEffect(() => {
        if (page) {
            // page存在 初始化变量

            if (page <= totalPages && /^\d+$/.test(page)) {
                setBlogsSelected(blogs)
                setKeyword('')
                setPageNum(Number(page))

            } else {
                navigate("/404")
            }
        } else if (searchParams.has('page')) {
            // 搜索情况下
            const tempPage = Number(searchParams.get('page'))
            const tempKeyword = searchParams.get('keyword')
            if (tempKeyword && /^\d+$/.test(tempPage)) {
                setPageNum(tempPage)
                setKeyword(tempKeyword)
                setBlogsSelected(handleSearch(blogs, tempKeyword))
            } else {
                navigate("/404")
            }
        } else {
            setKeyword('')
            navigate('/blog/1');
        }
    }, [page, blogsSelected, searchParams])

    // 分页页码的组件
    function paginationComponents() {
        if (pageNum) {
            if (keyword) {
                for (let i = 1; i <= totalPages; i++) {

                    i === pageNum ? paginationElement.push(<li className="active" key={i}><a href='#' onClick={(e) => e.preventDefault()}>
                        {i}
                    </a></li>) : paginationElement.push(<li key={i}>
                        <Link onClick={ClickHandler} to={`/blog?keyword=${keyword}&page=${i}`}>
                            {i}
                        </Link>
                    </li>)
                }
            } else {
                for (let i = 1; i <= totalPages; i++) {
                    i === pageNum ? paginationElement.push(<li className="active" key={i}><a href='#' onClick={(e) => e.preventDefault()}>
                        {i}
                    </a></li>) : paginationElement.push(<li key={i}>
                        <Link onClick={ClickHandler} to={`/blog/${i}`}>
                            {i}
                        </Link>
                    </li>)
                }
            }
        }
        return paginationElement
    }

    // 翻译里面的'1blog, 2blog'这种提取出id也就是1,2
    const extractNumbers = (str) => {
        const match = str.match(/\d+/g); // \d+ 匹配一个或多个数字
        return match ? match.join('') : ''; // 如果找到数字，则连接它们；否则返回空字符串
    }

    // 根据搜索词适合的blog
    function searchBlogs(searchKeyword, blogData) {
        const resultsBlogId = [];

        for (let blogId in blogData) {
            const blog = blogData[blogId]

            let isMatch = false;

            for (let property in blog) {
                const value = blog[property];
                if (Array.isArray(value)) {
                    for (let item of value) {
                        if (item.toLowerCase().includes(searchKeyword)) {
                            isMatch = true;
                            break;
                        }
                    }
                } else if (typeof value === 'string' && value !== 'blClass') {
                    if (value.toLowerCase().includes(searchKeyword)) {
                        isMatch = true;
                    }
                } else if (typeof value === 'object' && value !== null) {
                    for (let key in value) {
                        if (value[key].toLowerCase().includes(searchKeyword)) {
                            isMatch = true;
                            break;
                        }
                    }
                }
            }
            if (isMatch) {
                resultsBlogId.push(blogId);
                isMatch = false;
            }
        }
        return [...new Set(resultsBlogId)]
    }

    // 搜索的总函数，输出过滤的blogs
    const handleSearch = (givenBlogs, searchKeyword) => {
        const lowerCaseKeyword = searchKeyword.toLowerCase();
        const enResult = searchBlogs(lowerCaseKeyword, i18next.getResourceBundle('en', 'translation').blog)
        const zhResult = searchBlogs(lowerCaseKeyword, i18next.getResourceBundle('zh', 'translation').blog)
        const resultsBlogIdTranslated = [...new Set([...enResult, ...zhResult])]
        const resultsBlogId = resultsBlogIdTranslated.map(extractNumbers)
        const filteredBlog = givenBlogs.filter(item => resultsBlogId.includes(item.id))
        return filteredBlog
    }

    // 根据tag过滤的blogs
    // function findBlogsByTag(givenBlogs, selectedTag) {
    //     const filteredBlogs = givenBlogs.filter(blog => {
    //         const blogTags = Object.values(t(`blog.${blog.id}blog.tags`, { returnObjects: true }));
    //         return blogTags.includes(selectedTag);
    //     })
    //     console.log(filteredBlogs)
    //     return filteredBlogs
    // }

    // 分页两边的side button的组件
    function renderPaginationSideButton(number, label, angle) {
        if ((pageNum === 1 && label === 'Previous') ||
            (pageNum === totalPages && label === 'Next')) {
            return <a href="#" onClick={(e) => e.preventDefault()} aria-label={label}><i className={`fi ti-angle-${angle}`}></i></a>;
        } else if (pageNum <= totalPages && pageNum > 1) {
            if (keyword) {
                return <Link onClick={ClickHandler} to={`/blog?keyword=${keyword}&page=${pageNum + number}`} aria-label={number < 1 ? 'Previous' : 'Next'}>
                    <i className={`fi ti-angle-${number < 1 ? 'left' : 'right'}`}></i>
                </Link>
            } else {
                return <Link onClick={ClickHandler} to={`/blog/${pageNum + number}`} aria-label={number < 1 ? 'Previous' : 'Next'}>
                    <i className={`fi ti-angle-${number < 1 ? 'left' : 'right'}`}></i>
                </Link>
            }
        } else {
            return <a href="#" onClick={(e) => e.preventDefault()} aria-label={label}><i className={`fi ti-angle-${angle}`}></i></a>;
        }
    }


    function blogRender() {
        return (
            <div className="wpo-blog-content">
                {
                    blogsSelected.slice(startIndex, endIndex).map((blog) => (
                        <div className={`post ${t(`blog.${blog.id}blog.blClass`)}`} key={blog.id}>
                            <div className="entry-media video-holder">
                                <ImageWithLoading src={blog.blogSingleImg} alt={language === 'zh' ? '新闻封面' : 'blog cover'} ratio={2.1}></ImageWithLoading>
                                <VideoModal />
                            </div>
                            <div className="entry-meta">
                                <ul>
                                    <li><i className="fi flaticon-user"></i> {t("By")} {t(`blog.${blog.id}blog.authorTitle`)} </li>
                                    <li><i className="fi flaticon-calendar"></i> {t(`blog.${blog.id}blog.create_at`)}</li>
                                </ul>
                            </div>
                            <div className="entry-details">
                                <h3><Link onClick={ClickHandler} to={`/blog-single/${blog.id}`}>{t(`blog.${blog.id}blog.title`)}</Link></h3>
                                <p>{t(`blog.${blog.id}blog.quote`)}</p>
                                <Link onClick={ClickHandler} to={`/blog-single/${blog.id}`} className="read-more">{t("READMORE")}</Link>
                            </div>
                        </div>
                    ))
                }

                {
                    blogsSelected.length !== 0 ? <div className="pagination-wrapper pagination-wrapper-left">
                        <ul className="pg-pagination">
                            <li>
                                {
                                    renderPaginationSideButton(-1, 'Previous', 'left')
                                }
                            </li>
                            {paginationComponents()}
                            <li>
                                {
                                    renderPaginationSideButton(1, 'Next', 'right')
                                }
                            </li>
                        </ul>
                    </div> : null
                }

            </div>)
    }

    function resultsRender() {
        return blogsSelected.length !== 0 ?
            keyword ?
                <div className='search-result'>
                    {blogsSelected.length}
                    {
                        blogsSelected.length === 1 ? t("singleResult") : t("multipleResults")
                    }
                </div> : null : noResultsRender()
    }

    function noResultsRender() {
        return <div className='search-result'>
            <h3>{t("NoResults")}</h3>
            <br></br> <br></br>
            <h4>{t("NoResultsHints")}</h4> <br></br>
            <p>
                <ul style={{ "list-style-type": "circle" }}>
                    <li>{t("Hint1")}</li>
                    <li>{t("Hint2")}</li>
                </ul>
            </p>

        </div>
    }


    return (
        <section className="wpo-blog-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                        <div className='fade-in'>
                            {resultsRender()}
                            {blogRender()}
                        </div>
                    </div>
                    <BlogSidebar blLeft={props.blLeft} />
                </div>
            </div>
        </section>
    )
}

export default BlogList;