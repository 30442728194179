import React from 'react'
import { Link } from 'react-router-dom'
import HeaderTopbar2 from '../HeaderTopbar2/HeaderTopbar2'
import MobileMenu from '../MobileMenu/MobileMenu'
import Logo from '../../images/logo_navbar2.png'
import products from '../../api/newproduct'
import LanguageSwitcher from '../languageSwitcher/languageSwitcher'
import { useTranslation } from 'react-i18next';

const Header3 = (props) => {
    const categories = products.map((product) => {
        return product.category
    })

    const { t } = useTranslation();

    const uniqueCategories = [...new Set(categories)]

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <header id="header" className={`wpo-header-style-7 ${props.topbarNone}`}>
            <HeaderTopbar2 />
            <div className={`wpo-site-header ${props.hclass}`}>
                <nav className="navigation navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                <div className="mobail-menu">
                                    <MobileMenu />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6">
                                <div className="navbar-header">
                                    <Link onClick={ClickHandler} className="navbar-brand" to="/home"><img src={Logo}
                                        alt="logo" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-1 col-1">
                                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                    <button className="menu-close"><i className="ti-close"></i></button>
                                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/">{t('Home')}</Link>
                                        </li>
                                        <li><Link onClick={ClickHandler} to="/about">{t('AboutUs')}</Link></li>
                                        <li className="menu-item-has-children">
                                            <Link to="/product"
                                                onClick={ClickHandler} state={{ selectedCategory: "" }}>{t('Products')}</Link>
                                            <ul className="sub-menu">
                                                {
                                                    uniqueCategories.map((category, index) => {
                                                        return (
                                                            <li key={index}><Link onClick={ClickHandler} to="/product" state={{ selectedCategory: category }}
                                                            >{t(`ProductCategories.${category.split(" ").join("")}`)}</Link></li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </li>

                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/blog/1">{t('Blog')}</Link>
                                        </li>
                                        <li><Link onClick={ClickHandler} to="/contact">{t('Contact')}</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-2 col-2">
                                <div className="header-right">
                                    <LanguageSwitcher />
                                    <div className="close-form">
                                        <Link onClick={ClickHandler} className="theme-btn" to="/contact">
                                            {t('ContactUs')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header3;


// search button
// <div className="header-search-form-wrapper">
//     <div className="cart-search-contact">
//         <button onClick={() => setMenuState(!menuActive)} className="search-toggle-btn"><i
//             className={`fi ti-search ${menuActive ? "ti-close" : "fi "}`}></i></button>
//         <div className={`header-search-form ${menuActive ? "header-search-content-toggle" : ""}`}>
//             <form onSubmit={SubmitHandler}>
//                 <div>
//                     <input type="text" className="form-control"
//                         placeholder="Search here..." />
//                     <button type="submit"><i
//                         className="fi flaticon-search-interface-symbol"></i></button>
//                 </div>
//             </form>
//         </div>
//     </div>
// </div>




// <li className="menu-item-has-children">
//                                             <Link to="/service">Project</Link>
//                                             <ul className="sub-menu">
//                                                 <li><Link onClick={ClickHandler} to="/project">Project Style 1</Link></li>
//                                                 <li><Link onClick={ClickHandler} to="/project-s2">Project Style 2</Link></li>
//                                                 <li><Link onClick={ClickHandler} to="/project-s3">Project Style 3</Link></li>
//                                                 <li><Link onClick={ClickHandler} to="/project-single/1">Project Single</Link></li>
//                                             </ul>
//                                         </li>
//                                         <li className="menu-item-has-children">
//                                             <Link to="/project">Pages</Link>
//                                             <ul className="sub-menu">
//                                                 <li><Link onClick={ClickHandler} to="/team">Team</Link></li>
//                                                 <li><Link onClick={ClickHandler} to="/team-single/1">Team single</Link></li>
//                                                 <li><Link onClick={ClickHandler} to="/shop">Shop Page</Link></li>
//                                                 <li><Link onClick={ClickHandler} to="/shop-single">Shop Single</Link></li>
//                                                 <li><Link onClick={ClickHandler} to="/cart">Cart</Link></li>
//                                                 <li><Link onClick={ClickHandler} to="/checkout">Checkout</Link></li>
//                                                 <li><Link onClick={ClickHandler} to="/pricing">Pricing</Link></li>
//                                                 <li><Link onClick={ClickHandler} to="/404">404 Error</Link></li>
//                                                 <li><Link onClick={ClickHandler} to="/login">Login</Link></li>
//                                                 <li><Link onClick={ClickHandler} to="/register">Register</Link></li>
//                                             </ul>
//                                         </li>

// <ul className="sub-menu">
//     <li><Link onClick={ClickHandler} to="/blog">Blog right sidebar</Link></li>
//     <li><Link onClick={ClickHandler} to="/blog-left-sidebar">Blog left sidebar</Link></li>
//     <li><Link onClick={ClickHandler} to="/blog-fullwidth">Blog fullwidth</Link></li>
//     <li className="menu-item-has-children">
//         <Link onClick={ClickHandler} to="/">Blog details</Link>
//         <ul className="sub-menu">
//             <li><Link onClick={ClickHandler} to="/blog-single/1">Blog details right sidebar</Link>
//             </li>
//             <li><Link onClick={ClickHandler} to="/blog-single-left-sidebar/1">Blog details left
//                 sidebar</Link></li>
//             <li><Link onClick={ClickHandler} to="/blog-single-fullwidth/1">Blog details
//                 fullwidth</Link></li>
//         </ul>
//     </li>
// </ul>