import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import MissionVission from '../../components/MissionVission/MissionVission';
import About4 from '../../components/about4/about4';
import Testimonial from '../../components/Testimonial/Testimonial';
import FunFact from '../../components/FunFact/FunFact';
import Footer2 from '../../components/footer2/Footer2';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';


const AboutPage = () => {
    const language = useSelector(state => state.languageStatus.language);
    const title = language === 'en' ? 'About Ximai Electric' : '关于西迈电气';
    const description = language === 'en' ? 'Located in the historic city of Ezhou by the Yangtze River, Hubei Ximai Electric Equipment Co., Ltd. was established in 2006. With a vast facility spanning 40,000 sqm, we specialize in producing a wide range of energy-efficient transformers including dry-type, oil-immersed, and amorphous alloy models. Our product line also encompasses EV charging stations, low-voltage switchgear (GGD, GCS, GGJ) high-voltage cabinets, ring main units, high and low-voltage metering cabinets, and transformers in European or American styles, as well as urban construction projects like bus stops.' : '西迈电气设备有限公司位于历史悠久的鄂州市，依傍壮阔长江，自2006年成立以来，致力于生产各类高效节能变压器，包括干式、油浸式和非晶合金变压器。我们的产品线涵盖电动汽车充电站、低压开关柜（GGD、GCS、GGJ）、高压柜和高低压计量柜，以及具有欧美风格的充电站变压器和城市公交站台建设项目。作为国家电网、发电及智能制造领域的重要参与者，我们的产品远销亚洲、非洲等多个国家和地区，以卓越品质获得国家部门及用户的广泛认可。西迈电气秉承创新和卓越，致力于电气设备行业的发展，为全球基础设施建设贡献力量。';

    return (
        <Fragment>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <Navbar3 />
            <PageTitle pageTitle={'AboutUs'} pagesub={'About'} />
            <About4 />
            <FunFact />
            <MissionVission />
            <Testimonial />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPage;

// <TeamSection tClass={'wpo-team-section-s3'} />
