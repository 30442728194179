import React, { Fragment } from 'react';
import About from '../../components/about/about';
import BlogSection from '../../components/BlogSection/BlogSection';
import Footer from '../../components/footer/Footer';
import FunFact from '../../components/FunFact/FunFact';
import Hero from '../../components/hero/hero';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import abimg from '../../images/about.webp'
import Navbar4 from '../../components/Navbar4/Navbar4';
import Logo from '../../images/logo_navbar2.png'
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const HomePage = () => {
    const language = useSelector(state => state.languageStatus.language);
    const title = language === 'en' ? 'Ximai Electric' : '湖北西迈电气设备有限公司';
    const description = language === 'en' ? 'Rooted in China and serving the world, Ximai Electric specializes in designing and producing transformers, box-type substations, and a variety of electrical equipment. With a sprawling 40,000 sqm facility and a new 20,000 sqm factory, we boast an annual production capacity of 8,000 high and low voltage complete sets, 6,000 transformers, 2,000 box-type substations, and 10,000 tons of iron accessories. Our focus on energy-efficient transformers, including dry-type, oil-immersed, and amorphous alloy models, complements our extensive product line that ranges from EV charging stations and low-voltage switchboards (GGD, GCS, GGJ) to high-voltage cabinets and metering cabinets in European or American styles. Our commitment to quality, innovation, and customer satisfaction is evident in our achievements in national grid, power generation, and intelligent manufacturing sectors, earning widespread acclaim from state departments and customers alike. Discover more about our customized solutions, product center, quality assurance, and the latest company updates at Ximai Electric.' : '西迈电气立足中国，服务世界，专注于设计和生产变压器、箱式变电站及高低压成套设备和各种电子电工。公司占地40000平方米，新建厂房20000平方米，年产能力包括8000台高低压成套设备、6000台变压器、2000台箱式变电站和10000吨铁附件。我们致力于生产各种规格的节能变压器，包括干式、油浸式和非晶合金型号，同时提供电动汽车充电站、低压开关板（如GGD、GCS、GGJ）、高压柜及高低压计量柜等产品，并涵盖欧美风格的变压器以及城市建设项目如公交站台。西迈电气在国家电网、发电、交通和智能制造等领域取得卓越成就，深受国家相关部门和用户好评。了解更多关于我们的定制解决方案、产品中心、质量保证和最新动态。';
    return (
        <Fragment>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <Navbar4 Logo={Logo} />
            <Hero />
            <About abimg={abimg} />
            <ServiceSection />
            <ProjectSection />
            <BlogSection />
            <FunFact />
            <PartnerSection />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePage;