import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en_translation from "../../api/text/en_translation";
import cn_translation from "../../api/text/cn_translation";
import store from "../../store";

const defaultLanguage = store.getState().languageStatus.language;

store.subscribe(() => {
    const language = store.getState().languageStatus.language;
    if (i18n.language !== language) {
        i18n.changeLanguage(language); // update the i18n language when the Redux language changes
    }
});

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en_translation

            },
            zh: {
                translation: cn_translation
            }
        },
        lng: defaultLanguage, // 使用用户的浏览器语言作为默认语言
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });


export default i18n;
