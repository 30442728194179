import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const HeaderTopbar3 = () => {
    const { t } = useTranslation();
    return (
        <div className="topbar">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col col-lg-9 col-sm-12 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li><i className="fi flaticon-phone-call-1"></i> (0086) 0711-5921007</li>
                                <li><i className="fi flaticon-email-1"></i> hbxmdq@163.com</li>
                                <li><i className="fi flaticon-location"></i>{t("HeaderTopbar3.address")}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 col-12">
                        <div className="contact-info">
                            <Link className='theme-btn' to="/contact">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopbar3;