import React, { useRef, useEffect, useState } from 'react';

const LazyImage = ({ src, alt, ...props }) => {
    const [isVisible, setIsVisible] = useState(false);
    const imgRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                setIsVisible(true);
                observer.disconnect(); // Once image is loaded, there's no need to observe it
            }
        });

        const currentImg = imgRef.current;
        if (currentImg) {
            observer.observe(currentImg);
        }

        return () => {
            if (currentImg) {
                observer.unobserve(currentImg);
            }
        };
    }, [src]);

    return (
        <img
            ref={imgRef}
            src={isVisible ? src : undefined} // Only set src attribute when the image is visible
            alt={alt}
            {...props}
        />
    );
};

export default LazyImage;
