import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import RelatedProduct from '../../components/RelatedProduct/RelatedProduct';
import { useParams, useNavigate } from 'react-router-dom'
import Footer2 from '../../components/footer2/Footer2';
import Products from '../../api/newproduct'
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import ImageWithLoading from '../../components/loading/ImageWithLoading';

const ProjectSinglePage = (props) => {
    const { id } = useParams()
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const product = Products.find(
        (product) => {
            return product.id === parseInt(id)
        }
    )
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    if (!/^\d+$/.test(parseInt(id)) || !product) {
        window.location.href = '/404';
        return null;  // 阻止组件继续渲染
    }

    return (
        <Fragment>
            <Navbar3 />
            <PageTitle pageTitle={t(`ProductCategories.${product.category.split(" ").join("")}`)} pagesub={t('Product')} />
            <div className="wpo-project-single-area section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-12">
                            <div className="wpo-project-single-wrap">
                                <div className="wpo-project-single-item">
                                    <div className="row align-items-center">
                                        <div className="col-lg-7">
                                            <div className="wpo-project-single-title">
                                                <h3>{t(`products.${id}.name`)}</h3>
                                            </div>
                                            <p>{t(`products.${id}.intro`)}</p>

                                        </div>
                                        <div className="col-lg-5">
                                            <div className="wpo-project-single-content-des-right">
                                                <ul>
                                                    <li>{t("Type")} :<span>{t(`products.${id}.category`)}</span></li>
                                                    <li>{t("Model")} :<span>{t(`products.${id}.model`)}</span></li>
                                                    <li>{t("Design")} :<span>Ximai Electrical</span></li>
                                                    <li>{t("Duration")} :<span>{t(`products.${id}.duration`)}</span></li>
                                                    <li>{t("UpdateDate")} :<span>{t(`products.${id}.updateDate`)}</span></li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wpo-project-single-main-img">
                                        {product.img.map((img, index) => {
                                            return <ImageWithLoading key={index} src={img} alt={t(`products.${id}.name`)} ratio={2.1} />
                                        })}

                                    </div>
                                </div>
                                <div className="wpo-project-single-item list-widget">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {
                                                i18n.exists(`products.${product.id}.features`) && <div className="wpo-project-single-title">
                                                    <h3>{t("Features")}</h3>
                                                </div>
                                            }
                                            <ul>
                                                {
                                                    i18n.exists(`products.${product.id}.features`) && Object.values(t(`products.${product.id}.features`, { returnObjects: true })).map((feature, index) => (
                                                        <li key={index} className='features' >
                                                            {feature}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>

                                    </div>
                                </div>

                                <div className="wpo-project-single-item list-widget">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {
                                                i18n.exists(`products.${product.id}.condition`) && <div className="wpo-project-single-title">
                                                    <h3>{t("OperatingConditions")}</h3>
                                                </div>
                                            }

                                            <ul>
                                                {
                                                    i18n.exists(`products.${product.id}.condition`) &&
                                                    Object.values(t(`products.${product.id}.condition`, { returnObjects: true })).map((condition, index) => (
                                                        <li className="condition" key={index}>
                                                            {condition}
                                                        </li>
                                                    ))

                                                }
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div className="wpo-project-single-item list-widget">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {
                                                i18n.exists(`products.${product.id}.technicalParameters`) && <div className="wpo-project-single-title">
                                                    <h3>{t("technicalParameters")}</h3>
                                                </div>
                                            }
                                            <ul >
                                                {
                                                    i18n.exists(`products.${product.id}.technicalParameters`) &&
                                                    Object.values(t(`products.${product.id}.technicalParameters`, { returnObjects: true })).map((techParam, index) => (
                                                        <li key={index} className='techParam'>
                                                            {techParam}
                                                        </li>
                                                    ))

                                                }
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div className="wpo-project-single-item list-widget">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {
                                                i18n.exists(`products.${product.id}.table`) &&
                                                <div className="wpo-project-single-title">
                                                    <h3>{t("technicalParameters")}</h3>
                                                </div>
                                            }
                                            <ul >
                                                {

                                                    i18n.exists(`products.${product.id}.table`) &&
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                {
                                                                    Object.values(t(`products.${product.id}.table.headers`, { returnObjects: true })).map((header, index) => (
                                                                        <TableCell key={index}>{header}</TableCell>
                                                                    ))
                                                                }
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {t(`products.${product.id}.table.rows`, { returnObjects: true }).map((row, rowIndex) => (
                                                                <TableRow key={rowIndex}>
                                                                    {row.map((cell, cellIndex) => (
                                                                        <TableCell key={cellIndex}>{cell}</TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>

                                                }
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <RelatedProduct category={product.category} id={product.id} />
                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-contact-area">
                                        <div className='row'>
                                            <div className="wpo-contact-title col-lg-8">
                                                <h2>{t("productsText.discuss")}</h2>
                                                <p>{t("productsText.touch")}</p>
                                            </div>
                                            <div className="wpo-contact-form-area col-lg-4">
                                                <button type="submit" className="theme-btn"
                                                    onClick={() => {
                                                        ClickHandler();
                                                        navigate('/contact');
                                                    }}
                                                >{t("ContactUs")}</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer2 />
            <Scrollbar />
        </Fragment>
    )
};
export default ProjectSinglePage;

// Feature right side comment
// <div className="col-lg-6">
//     <div className="wpo-project-single-item-quote">
//         <p>"Amazing looking theme and instantly turns your application into a great
//             looking one. Really shows that pro_ fessionals built this theme up. Very
//             happy with the way the theme looks ."</p>
//         <span>Robert - <span>Yellow Theme</span></span>
//     </div>
// </div>

// <div className="wpo-project-single-item">
//     <div className="wpo-project-single-title">
//         <h3>Our approach</h3>
//     </div>
//     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consequat suspendisse aenean
//         tellus augue morbi risus. Sit morbi vitae morbi sed urna sed purus. Orci facilisi
//         eros sed pellentesque. Risus id sed tortor sed scelerisque. Vestibulum elit
//         elementum, magna id viverra non, velit. Pretium, eros, porttitor fusce auctor vitae
//         id. Phasellus scelerisque nibh eleifend vel enim mauris purus. Rutrum vel sem
//         adipiscing nisi vulputate molestie scelerisque molestie ultrices. Eu, fusce
//         vulputate diam interdum morbi ac a.</p>
// </div>

// Operating Condition right side

// <div className="col-lg-6 list-widget-s">
//     <div className="wpo-project-single-title">
//         <h3>Results</h3>
//     </div>
//     <ul>
//         <li>Mauris dignissim blandit cursus imperdiet accumsan lorem.</li>
//         <li>Nam id in non sed cras purus nunc et.</li>
//         <li>Mauris orci, cursus nisl odio est adipiscing gravida magna eget.</li>
//         <li>Quis mauris vel felis convallis nulla dignissim.</li>
//     </ul>
// </div>