import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import videoBg from '../../video/ximai-intro.mp4'
import videoBgBackup from '../../video/ximai-intro.webm'
import { useTranslation } from 'react-i18next';
import poster from '../../images/poster.webp';
const Hero = () => {
    const { t } = useTranslation();
    const heroTitles = t("heroTitle").split('|');
    return (
        <section className="hero hero-slider-wrapper hero-style-1">
            <div className="overlay">
                <h1>
                    {heroTitles[0]}<br />
                    {heroTitles[1]}
                </h1>
            </div>
            <div className="video-container">
                <video autoPlay muted loop id="video" poster={poster}>
                    <source src={videoBg} type="video/mp4" />
                    <source src={videoBgBackup} type="video/webm" />
                    {t('NotSupportVideo')}
                </video>
            </div>
        </section>
    )
}

export default Hero;

// <div className="hero-slider">

// </div>

// <Slider {...settings}>
//     <div className="slide">
//         <div className="container">
//             <div className="row">
//                 <div className="col col-lg-6 col-md-8 col-sm-12 slide-caption">
//                     <div className="slide-title-sub">
//                         <h5>Trusted Business Partner.</h5>
//                     </div>
//                     <div className="slide-title">
//                         <h2>The Fastest way to achieve success</h2>
//                     </div>
//                     <div className="slide-subtitle">
//                         <p>It was popularised in the 1960s with the release sheets. We bring the right
//                             people together</p>
//                     </div>
//                     <div className="btns">
//                         <Link to="/about" className="theme-btn">Explore More</Link>
//                     </div>
//                     <div className="slider-pic">
//                         <img src={hero1} alt="" />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
//     <div className="slide">
//         <div className="container">
//             <div className="row">
//                 <div className="col col-lg-6 col-md-8 col-sm-12 slide-caption">
//                     <div className="slide-title-sub">
//                         <h5>Trusted Business Partner.</h5>
//                     </div>
//                     <div className="slide-title">
//                         <h2>Provide Financial Solution Service.</h2>
//                     </div>
//                     <div className="slide-subtitle">
//                         <p>It was popularised in the 1960s with the release sheets. We bring the right
//                             people together</p>
//                     </div>
//                     <div className="btns">
//                         <Link to="/about" className="theme-btn">Explore More</Link>
//                     </div>
//                     <div className="slider-pic">
//                         <img src={hero2} alt="" />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
// </Slider>