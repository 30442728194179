import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import blogs from '../../api/blogs'
import BlogSingle from '../../components/BlogDetails/BlogSingle.js'
import Footer2 from '../../components/footer2/Footer2';
import { useTranslation } from 'react-i18next';


const BlogDetails = () => {

    const { id } = useParams()
    const { t } = useTranslation();

    if (!/^\d+$/.test(parseInt(id))) {
        window.location.href = '/404';
        return null;  // 阻止组件继续渲染
    }

    const BlogDetails = blogs.find(item => item.id === id)

    if (!BlogDetails) {
        window.location.href = '/404';
        return null;  // 阻止组件继续渲染
    }


    return (
        <Fragment>
            <Navbar3 />
            <PageTitle pageTitle={t(`blog.${BlogDetails.id}blog.title`)} pagesub={'Blog'} />
            <BlogSingle />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    )
};
export default BlogDetails;
