import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Box } from '@material-ui/core';
import products from '../../api/newproduct';
import MobileCategory from '../MobileCategory/MobileCategory'
import { useTranslation } from 'react-i18next';

function ProductCategories({ onCategorySelect, selectedFromNavibar }) {
    const { t } = useTranslation();
    const [selectedCategory, setSelectedCategory] = useState(null);

    const categories = products.map((product) => {
        return product.category
    })

    const uniqueCategories = [...new Set(categories)]

    useEffect(() => {
        if (typeof selectedFromNavibar !== 'undefined') {
            setSelectedCategory(selectedFromNavibar)
        }
    }, [selectedFromNavibar]
    )

    return (
        <Box sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}>
            <MobileCategory
                onCategorySelect={(category) => onCategorySelect(category)}
                categories={uniqueCategories} />
            <div className="category-list">
                <div>{t('ProductCategory')}</div>
                <List component="nav" className="customList"
                    style={{
                        marginLeft: '1%',
                        width: 'calc(80%)',
                        paddingTop: '30px'
                    }}>
                    <ListItem
                        button
                        onClick={() => {
                            onCategorySelect("");
                            setSelectedCategory("");
                        }}
                        className={`categoryItem ${selectedCategory === "" ? "categoryItemSelected" : ""}`}
                    >
                        <ListItemText primary={
                            <div className="listItemText">
                                {t('AllProducts')}
                            </div>} />
                    </ListItem>
                    {
                        uniqueCategories.map((category, index) => (
                            <React.Fragment key={index}>
                                <ListItem
                                    button
                                    onClick={() => {
                                        onCategorySelect(category);
                                        setSelectedCategory(category);
                                    }}
                                    className={`categoryItem ${selectedCategory === category ? "categoryItemSelected" : ''}`}
                                >

                                    <ListItemText primary={
                                        <div className="listItemText">
                                            {t(`ProductCategories.${category.split(' ').join('')}`)}
                                        </div>} />
                                </ListItem>
                            </React.Fragment>
                        ))
                    }
                </List>
            </div>
        </Box>

    );
}

export default ProductCategories;
