import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import BlogList from '../../components/BlogList/BlogList.js'
import Navbar3 from '../../components/Navbar3/Navbar3';
import Footer2 from '../../components/footer2/Footer2';
import Scrollbar from '../../components/scrollbar/scrollbar'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBounday.js';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const BlogPage = () => {
    const language = useSelector(state => state.languageStatus.language);
    const title = language === 'en' ? 'Ximai Electric News - Latest Updates on Electrical Solutions and Projects' : '西迈电气最新动态';
    const description = language === 'en' ? "Stay updated with the latest news from Ximai Electric. Discover insights into our projects and commitment to quality through our introductory video. Dive into our professional electrical solutions provided for Lingkong District People's Hospital, showcasing our expertise in empowering healthcare facilities with robust infrastructure.Learn about the unparalleled technical support we offered in the power distribution renovation for Ezhou City's First Hospital, ensuring safer and more reliable electricity supply. Follow our journey as we continually strive for excellence in the electrical industry, enhancing critical institutions with our advanced technology and dedication to safety." : '关注西迈电气的最新动态。通过我们的介绍视频，深入了解我们的项目和质量承诺。探索我们为临空区人民医院提供的专业电力解决方案，展现我们在加强医疗设施基础设施方面的专业能力。了解我们为鄂州市第一医院提供的无与伦比的电力配电改造技术支持，确保医院用电更安全、更可靠。跟随我们的脚步，不断追求电气行业的卓越，用我们的先进技术和对安全的承诺增强关键机构的能力。';
    return (
        <ErrorBoundary>
            <Fragment>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                </Helmet>
                <Navbar3 />
                <PageTitle pageTitle={'LatestNews'} pagesub={'Blog'} />
                <BlogList />
                <Footer2 />
                <Scrollbar />
            </Fragment>
        </ErrorBoundary>

    )
};
export default BlogPage;

