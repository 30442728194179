
import React, { Fragment } from 'react';
import ContactForm from '../ContactFrom/ContactForm'
import wechatImg from '../../images/wechatImg.webp'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Map, ControlBar, APILoader, MapType, Scale, Marker, InfoWindow } from '@pansy/react-amap';


const handleScrollToElement = () => {
    const element = document.getElementById('map');
    element.scrollIntoView({ behavior: 'smooth' });
};



const Contactpage = () => {
    const { t } = useTranslation();
    const language = useSelector(state => state.languageStatus.language);
    const contactUsTranslate = (key) => {
        return t(`contactUspage.${key}`);
    };
    const phoneNumber = language === 'zh' ? '15926013777  ' : '+86 13476184091';


    return (
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-place"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>{contactUsTranslate("AddressTitle")}</h2>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <p><a href="#" onClick={handleScrollToElement}>{contactUsTranslate("SpecificAddress")}</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>{contactUsTranslate("ChatwithUs")}</h2>
                                            <p><a href="mailto:hbxmdq@163.com">{contactUsTranslate("Email")}hbxmdq@163.com</a></p>
                                            <p>
                                                <span className='wechatLink'>
                                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                    <a href="#">{contactUsTranslate("WeChat")}</a>
                                                    <img
                                                        className='wechatQR'
                                                        src={wechatImg}
                                                        alt={language === 'en' ? "Wechat" : "微信"}
                                                    /></span> / <a href="https://wa.me/8613476184091">Whatsapp</a> / <a href="skype:13476184091?call">Skype</a>
                                                : <br></br><a href="tel:+008613476184091">+86 13476184091</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>{contactUsTranslate("CallUs")}</h2>
                                            <p> <a href="tel:+008607115921007">{contactUsTranslate("Tel")}0711-5921007</a></p>
                                            <p><a href="tel:+008607115921066">{contactUsTranslate("Fax")}0711-5921066</a></p>
                                            <p> <a href="tel:+008613476184091">{contactUsTranslate("Phone")}{phoneNumber}</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wpo-contact-title">
                            <h2>{contactUsTranslate("OnlineQuote")}</h2>
                            <hr className="solid" />
                            <p>{contactUsTranslate("OnlineQuoteDescription")}</p>
                        </div>
                        <div className="wpo-contact-form-area">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>


            <section className="wpo-contact-map-section">

                <div id='map' className="wpo-contact-map">
                    {
                        language === 'zh' ?
                            <Fragment>
                                <APILoader
                                    mapKey="2ea64c2bb1a635c94f882f7581829d4c"
                                    onComplete={(obj) => {
                                        console.log('已加载 AMap Api');
                                        console.log(obj);
                                    }}
                                >
                                    <Map viewMode="3D" zoom={16.8} center={[114.9720, 30.386]}>
                                        <ControlBar />
                                        <MapType />
                                        <Scale />
                                        <InfoWindow
                                            position={[114.9720, 30.3848]}
                                            visible={true}
                                            isCustom={false}
                                            content={'西迈电气设备有限公司'}
                                            size={{
                                                width: 200,
                                                height: 140,
                                            }}
                                            offset={[0, 0]}
                                        />
                                        <Marker
                                            position={{
                                                longitude: 114.97171,
                                                latitude: 30.38542
                                            }}
                                        />
                                    </Map>
                                </APILoader>

                            </Fragment>

                            :
                            <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3441.8055161224925!2d114.97139999999999!3d30.384880000000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x342e633444910891%3A0x86fc79820138692a!2sHubei%20Ximai%20Electr.%20Equip.%20Co.%2CLtd.!5e0!3m2!1sen!2shk!4v1706004548792!5m2!1sen!2shk"></iframe>
                    }
                </div>
            </section>
        </section>
    )
}

export default Contactpage;
