import React, { Fragment } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { changeLanguage } from '../../store/modules/translations'

function LanguageSwitcher() {
    const dispatch = useDispatch()

    return (
        <Fragment>
            <Button color="inherit" className="mobileView" onClick={() => dispatch(changeLanguage('zh'))}>中</Button>
            <span>/</span>
            <Button color="inherit" className="mobileView" onClick={() => dispatch(changeLanguage('en'))}>en</Button>
        </Fragment>
    );
}

export default LanguageSwitcher;
