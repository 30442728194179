import React from 'react'
import { Link } from 'react-router-dom'
import HeaderTopbar3 from '../HeaderTopbar3/HeaderTopbar3'
import MobileMenu from '../MobileMenu/MobileMenu'
import products from '../../api/newproduct';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../languageSwitcher/languageSwitcher'

const Header4 = (props) => {
    // const [menuActive, setMenuState] = useState(false);
    const categories = products.map((product) => {
        return product.category
    })

    const uniqueCategories = [...new Set(categories)]
    // const SubmitHandler = (e) => {
    //     e.preventDefault()
    // }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const { t } = useTranslation();

    return (
        <header id="header" className={`wpo-header-style-3 ${props.topbarNone}`}>
            <HeaderTopbar3 />
            <div className={`wpo-site-header ${props.hclass}`}>
                <nav className="navigation navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                <div className="mobail-menu">
                                    <MobileMenu />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6">
                                <div className="navbar-header">
                                    <Link onClick={ClickHandler} className="navbar-brand" to="/home"><img src={props.Logo}
                                        alt="logo" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-1 col-1">
                                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                    <button className="menu-close"><i className="ti-close"></i></button>
                                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/">{t('Home')}</Link>

                                        </li>
                                        <li><Link onClick={ClickHandler} to="/about">{t('AboutUs')}</Link></li>
                                        <li className="menu-item-has-children">
                                            <Link to="/product" onClick={ClickHandler} state={{ selectedCategory: "" }}>{t('Products')}</Link>
                                            <ul className="sub-menu">
                                                {
                                                    uniqueCategories.map((category, index) => {
                                                        return (
                                                            <li key={index}><Link onClick={ClickHandler} to="/product" state={{ selectedCategory: category }}
                                                            >{t(`ProductCategories.${category.split(" ").join("")}`)}</Link></li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </li>


                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/blog/1">{t('Blog')}</Link>

                                        </li>
                                        <li><Link onClick={ClickHandler} to="/contact">{t('Contact')}</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-2 col-2">
                                <div className="header-right">
                                    <LanguageSwitcher />
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header4;