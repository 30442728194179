import React from 'react';
import { Link } from 'react-router-dom'
import blogs from '../../api/blogs';
import { useParams } from 'react-router-dom'
import BlogSidebar from '../BlogSidebar/BlogSidebar.js'
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const BlogSingle = (props) => {
    const { t } = useTranslation();
    const { id } = useParams()
    const intId = parseInt(id)
    const postUrl = `https://ximaielectric.com/blog-single/${id}`;
    const postTitle = t(`blog.${id}blog.title`);


    const BlogDetails = blogs.find(item => item.id === id)


    // Properly arrange size of the pictures in a blog
    // const imgContent = () => {
    //     if (!BlogDetails.numImgContent || BlogDetails.numImgContent <= 0) return <div></div>;

    //     return (
    //         <div className="gallery">
    //             {BlogDetails.imgContent.slice(0, BlogDetails.numImgContent).map((imgSrc, index) => (
    //                 <div key={index}>
    //                     <img src={imgSrc} alt="" />
    //                 </div>
    //             ))}
    //         </div>
    //     );
    // };

    if (!BlogDetails) {
        window.location.href = '/404';
        return null;  // 阻止组件继续渲染
    }

    return (
        <section className="wpo-blog-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                        <Button color="primary" style={{ marginBottom: '10px' }}>
                            <Link to={-1}>
                                <ArrowBackIcon style={{ paddingBottom: '3px' }} />{t("Back")}</Link>
                        </Button>
                        <div className="wpo-blog-content">
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={BlogDetails.blogSingleImg} alt={t(`blog.${id}blog.title`)} />
                                </div>
                                <div className="entry-meta">
                                    <ul>
                                        <li><i className="fi flaticon-user"></i> {t("By")} <Link to="/blog-single/1">{t(`blog.${id}blog.author`)}</Link> </li>
                                        <li><i className="fi flaticon-calendar"></i> {t(`blog.${id}blog.create_at`)}</li>
                                    </ul>
                                </div>
                                <h2>{t(`blog.${id}blog.title`)}</h2>
                                {t(`blog.${id}blog.blockquote`) !== '' && <blockquote>{t(`blog.${id}blog.blockquote`)}</blockquote>}

                                {Object.values(t(`blog.${id}blog.description`, { returnObjects: true })).map((description, index) => (
                                    <p key={index} className='features' >
                                        {description}
                                    </p>
                                ))}

                            </div>

                            <div className="tag-share clearfix">
                                <div className="tag">
                                    <span>{t("Tags")}</span>
                                    <ul>
                                        {Object.values(t(`blog.${id}blog.tags`, { returnObjects: true })).map((tag, index) => (
                                            <li key={index}>{tag}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className="tag-share-s2 clearfix">
                                <div className="tag">
                                    <span>{t("Share")} </span>
                                    <ul>
                                        <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${postUrl}`} target="_blank" rel="noopener noreferrer">{t("facebook")}</a></li>
                                        <li><a href={`https://twitter.com/intent/tweet?text=${postTitle}&url=${postUrl}`} target="_blank" rel="noopener noreferrer">{t("twitter")}</a></li>
                                        <li><a href={`https://www.linkedin.com/sharing/share-offsite/?url=${postUrl}`} target="_blank" rel="noopener noreferrer">{t("linkedin")}</a></li>
                                        <li><a href={`https://pinterest.com/pin/create/button/?url=${postUrl}&description=${postTitle}`} target="_blank" rel="noopener noreferrer">{t("pinterest")}</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="more-posts">
                                <div className="previous-post">
                                    {
                                        id === blogs[0].id ? <Link onClick={(e) => {
                                            e.preventDefault()
                                        }} to="#">
                                            <span className="post-control-link">{t("PreviousPost")}</span>
                                            <span className="post-name">{t("Thisisalreadythefirstpost")}</span>
                                        </Link> :
                                            <Link onClick={ClickHandler} to={`/blog-single/${intId - 1}`}>
                                                <span className="post-control-link">{t("PreviousPost")}</span>
                                                <span className="post-name">{t(`blog.${intId - 1}blog.title`)}</span>
                                            </Link>
                                    }

                                </div>
                                <div className="next-post">
                                    {
                                        id === blogs[blogs.length - 1].id ? <Link onClick={(e) => {
                                            e.preventDefault()
                                        }} to="#">
                                            <span className="post-control-link">{t("NextPost")}</span>
                                            <span className="post-name">{t("Thisisalreadythelastpost")}</span>
                                        </Link> :
                                            <Link onClick={ClickHandler} to={`/blog-single/${intId + 1}`}>
                                                <span className="post-control-link">{t("NextPost")}</span>
                                                <span className="post-name">{t(`blog.${intId + 1}blog.title`)}</span>
                                            </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <BlogSidebar blLeft={props.blLeft} />
                </div>
            </div>
        </section>
    )

}

export default BlogSingle;

// <div className="comments-area">
//     <div className="comments-section">
//         <h3 className="comments-title">Comments</h3>
//         <ol className="comments">
//             <li className="comment even thread-even depth-1" id="comment-1">
//                 <div id="div-comment-1">
//                     <div className="comment-theme">
//                         <div className="comment-image"><img src={blog3} alt="" /></div>
//                     </div>
//                     <div className="comment-main-area">
//                         <div className="comment-wrapper">
//                             <div className="comments-meta">
//                                 <h4>John Abraham <span className="comments-date">January 12,2022
//                                     At 9.00am</span></h4>
//                             </div>
//                             <div className="comment-area">
//                                 <p>I will give you a complete account of the system, and
//                                     expound the actual teachings of the great explorer of
//                                     the truth, </p>
//                                 <div className="comments-reply">
//                                     <Link className="comment-reply-link" to="/blog-single/1"><span>Reply</span></Link>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <ul className="children">
//                     <li className="comment">
//                         <div>
//                             <div className="comment-theme">
//                                 <div className="comment-image"><img src={blog4} alt="" /></div>
//                             </div>
//                             <div className="comment-main-area">
//                                 <div className="comment-wrapper">
//                                     <div className="comments-meta">
//                                         <h4>Lily Watson <span className="comments-date">January
//                                             12,2022 At 9.00am</span></h4>
//                                     </div>
//                                     <div className="comment-area">
//                                         <p>I will give you a complete account of the system,
//                                             and expound the actual teachings of the great
//                                             explorer of the truth, </p>
//                                         <div className="comments-reply">
//                                             <Link className="comment-reply-link" to="/blog-single/1"><span>Reply</span></Link>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <ul>
//                             <li className="comment">
//                                 <div>
//                                     <div className="comment-theme">
//                                         <div className="comment-image"><img src={blog5} alt="" /></div>
//                                     </div>
//                                     <div className="comment-main-area">
//                                         <div className="comment-wrapper">
//                                             <div className="comments-meta">
//                                                 <h4>John Abraham <span className="comments-date">January
//                                                     12,2022 At 9.00am</span></h4>
//                                             </div>
//                                             <div className="comment-area">
//                                                 <p>I will give you a complete account of the
//                                                     system, and expound the actual teachings
//                                                     of the great explorer of the truth, </p>
//                                                 <div className="comments-reply">
//                                                     <Link className="comment-reply-link" to="/blog-single/1"><span>Reply</span></Link>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </li>
//                         </ul>
//                     </li>
//                 </ul>
//             </li>
//             <li className="comment">
//                 <div>
//                     <div className="comment-theme">
//                         <div className="comment-image"><img src={blog3} alt="" /></div>
//                     </div>
//                     <div className="comment-main-area">
//                         <div className="comment-wrapper">
//                             <div className="comments-meta">
//                                 <h4>John Abraham <span className="comments-date">January 12,2022
//                                     At 9.00am</span></h4>
//                             </div>
//                             <div className="comment-area">
//                                 <p>I will give you a complete account of the system, and
//                                     expound the actual teachings of the great explorer of
//                                     the truth, </p>
//                                 <div className="comments-reply">
//                                     <Link className="comment-reply-link" to="/blog-single/1"><span>Reply</span></Link>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </li>
//         </ol>
//     </div>
//     <div className="comment-respond">
//         <h3 className="comment-reply-title">Post Comments</h3>
//         <form onSubmit={submitHandler} id="commentform" className="comment-form">
//             <div className="form-textarea">
//                 <textarea id="comment" placeholder="Write Your Comments..."></textarea>
//             </div>
//             <div className="form-inputs">
//                 <input placeholder="Website" type="url" />
//                 <input placeholder="Name" type="text" />
//                 <input placeholder="Email" type="email" />
//             </div>
//             <div className="form-submit">
//                 <input id="submit" value="Post Comment" type="submit" />
//             </div>
//         </form>
//     </div>
// </div>

// <div className="author-box">
//     <div className="author-avatar">
//         <Link to="/blog-single/1" target="_blank"><img src={blog6} alt="" /></Link>
//     </div>
//     <div className="author-content">
//         <Link to="/blog-single/1" className="author-name">Author: Ximai Electrical</Link>
//         <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.</p>
//         <div className="socials">
//             <ul className="social-link">
//                 <li><Link to="/blog-single/1"><i className="ti-facebook"></i></Link></li>
//                 <li><Link to="/blog-single/1"><i className="ti-twitter-alt"></i></Link></li>
//                 <li><Link to="/blog-single/1"><i className="ti-linkedin"></i></Link></li>
//                 <li><Link to="/blog-single/1"><i className="ti-instagram"></i></Link></li>
//             </ul>
//         </div>
//     </div>
// </div>